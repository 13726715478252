import { createEffect, createEvent, createStore } from 'effector';
import { DebugInfoCollectorEventBus, DebugInfoReport } from '@/services/DebugInfoCollector';
import { DebugInfoState } from '@/store/debug-info/DebugInfoState';
import { DebugInfo } from '@/services/Messenger';
import { EvictingQueue } from '@/helpers/EvictingQueue';
import { BitrateStore } from '@/store/debug-info/BitrateStore';
import { VideoTrackSize } from '@/store/statistics';
import { DeepReadonly } from 'vue';

const REPORT_QUEUE_LENGTH = 20;

const $debugInfo = createStore<DebugInfoState>({
  isCollectingActive: false,
  lastReport: null,
  collector: null,
  endpointReportMap: {},
});

const $incomingBitrate = createStore<BitrateStore>({
  current: 0,
  history: new EvictingQueue(REPORT_QUEUE_LENGTH),
});
const updateIncomingBitrate = createEvent<number>();

const $outgoingBitrate = createStore<BitrateStore>({
  current: 0,
  history: new EvictingQueue(REPORT_QUEUE_LENGTH),
});
const updateOutgoingBitrate = createEvent<number>();

const $availableOutgoingBitrate = createStore<BitrateStore>({
  current: 0,
  history: new EvictingQueue(REPORT_QUEUE_LENGTH),
});
const updateAvailableOutgoingBitrate = createEvent<number>();

const debugInfoEventBus: DebugInfoCollectorEventBus = {
  changeCollectingState: createEvent(),
  collectDebugInfo: createEvent(),
};

const startDebugFx = createEffect<void, void>();
const stopDebugFx = createEffect<void, void>();
const handleDebugInfoFx = createEffect<
  DebugInfo,
  { endpointId: string; report: DebugInfoReport }
>();

const $videoTrackSizeMap = createStore<
  Record<string, EvictingQueue<[size: VideoTrackSize, timestamp: number]>>
>({});
const updateVideoTrackSizeOfEndpoint = createEvent<[endpointId: string, size: VideoTrackSize]>();

const pickDebugInfo = <T extends keyof DebugInfoReport>(
  debugInfo: DebugInfoState | DeepReadonly<DebugInfoState>,
  endpointId: string,
  fieldName: T,
  defaultValue: DebugInfoReport[T]
): [value: DebugInfoReport[T], timestamp: number][] =>
  debugInfo.endpointReportMap[endpointId]?.reports?.map((report) => [
    report[fieldName] || defaultValue,
    report.timestamp,
  ]) || [];

export {
  DebugInfoState,
  BitrateStore,
  REPORT_QUEUE_LENGTH,
  $debugInfo,
  $incomingBitrate,
  $outgoingBitrate,
  $availableOutgoingBitrate,
  $videoTrackSizeMap,
  debugInfoEventBus,
  startDebugFx,
  stopDebugFx,
  handleDebugInfoFx,
  pickDebugInfo,
  updateIncomingBitrate,
  updateOutgoingBitrate,
  updateAvailableOutgoingBitrate,
  updateVideoTrackSizeOfEndpoint,
};
