
  import { defineComponent, onMounted, ref, watch } from 'vue';
  import TextArea from '@/components/chat/TextArea.vue';
  import { openDrawer } from '@/store/drawer';
  import { $chatContent, $isChatLoading, resetMessageCounter } from '@/store/chat/index';
  import { Button, Spinner } from '@voximplant/spaceui';
  import Message from '@/components/chat/Message.vue';
  import { useIsMobileScreen } from '@/hooks/isMobile';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'effector-vue/composition';
  import { screenInnerHeight } from '@/hooks/useWindowInnerHeight';

  const __default__ = defineComponent({
    name: 'ChatArea',
    components: {
      Message,
      TextArea,
      Button,
      Spinner,
    },
    props: {
      chatList: {
        type: Array,
        default: () => [],
      },
    },
    setup() {
      const { t } = useI18n();
      const { isMobileScreen } = useIsMobileScreen();
      const chatContent = useStore($chatContent);
      const isChatLoading = useStore($isChatLoading);
      const messagesWrap = ref<HTMLDivElement | null>(null);
      const scrollChatToTop = () => {
        messagesWrap.value?.scrollTo({
          top: messagesWrap.value.scrollHeight,
          behavior: 'smooth',
        });
      };
      onMounted(() => {
        resetMessageCounter();
        scrollChatToTop();
      });
      watch(chatContent, scrollChatToTop, { flush: 'post' });
      return {
        openDrawer,
        isChatLoading,
        isMobileScreen,
        messagesWrap,
        screenInnerHeight,
        t,
      };
    },
  });

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "38276f9e": (_ctx.screenInnerHeight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__