
  import { defineComponent, ref } from 'vue';
  import SignInAccount from '@/components/accounts/SignInAccount.vue';
  import { Button, Select, Input } from '@voximplant/spaceui';
  import { useStore } from 'effector-vue/composition';
  import { authStore, changeMyDisplayName } from '@/store/auth';

  export default defineComponent({
    name: 'Profile',
    components: {
      SignInAccount,
      Button,
      Select,
      Input,
    },
    props: {
      languagesList: {
        type: Array,
        default: () => [
          {
            label: 'Russian',
            value: 1,
          },
          {
            label: 'English',
            value: 2,
          },
          {
            label: 'Spanish',
            value: 3,
          },
        ],
      },
    },
    setup(props) {
      const activeItem = ref(props.languagesList[0]);
      const auth = useStore(authStore);
      const editIcon = { spriteUrl: '/image/videoconf-icons.svg', name: 'ic25-edit-gray' };
      const isEditing = ref(false);
      const startEditing = () => {
        isEditing.value = true;
      };
      const editedDisplayName = ref(auth.value.displayName as string);
      const setNewDisplayName = () => {
        if (!isEditing.value) return;
        isEditing.value = false;
        if (editedDisplayName.value === auth.value.displayName) {
          return;
        }
        changeMyDisplayName([auth.value.username as string, editedDisplayName.value]);
      };
      const restoreDisplayName = () => {
        isEditing.value = false;
        editedDisplayName.value = auth.value.displayName as string;
      };

      return {
        activeItem,
        auth,
        editIcon,
        isEditing,
        editedDisplayName,
        startEditing,
        setNewDisplayName,
        restoreDisplayName,
      };
    },
  });
