const videoSize: Record<string, [width: number, height: number]> = {};

const isVideoSizeChanged = (endpoint: string, ...area: [width: number, height: number]) => {
  const [width, height] = area;
  const isChanged = videoSize[endpoint]?.[0] !== width || videoSize[endpoint]?.[1] !== height;
  if (isChanged && typeof width !== 'undefined' && typeof height !== 'undefined') {
    videoSize[endpoint] = [width, height];
  }
  return isChanged;
};

const lastTimeVideoSizeRequested = { value: 'unknown' };

export { isVideoSizeChanged, lastTimeVideoSizeRequested };
