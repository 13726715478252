
  import { computed, defineComponent, PropType, ref, toRef, watch } from 'vue';
  import MediaSlotReactions from '@/components/layouts/MediaSlotReactions.vue';
  import UserVideoMock from '@/components/layouts/UserVideoMock.vue';
  import UsersOverflowMock from '@/components/layouts/UsersOverflowMock.vue';
  import { Button } from '@voximplant/spaceui';
  import { useStore } from 'effector-vue/composition';
  import { $mirrorStore } from '@/store/mirrorMedia';
  import { $devices } from '@/store/devices';
  import { useIsMobilePlatform } from '@/hooks/isMobile';
  import VideoElement from '@/components/media/VideoElement.vue';
  import { RenderVideoStore } from '@/store/endpointMedia/RenderVideoStore';
  import { $hasSeparateSharing, $sharing } from '@/store/sharing/index';
  import { pinByKind } from '@/helpers/pin';
  import { authStore } from '@/store/auth';
  import VideoSlotLabel from '@/components/layouts/VideoSlotLabel.vue';
  import { getVideoSlotSize } from '@/helpers/getVideoSlotSize';
  import { $users } from '@/store/users';
  import TechnicalLayout from '@/components/layouts/TechnicalLayout.vue';
  import { useResolutionStats } from '@/hooks/useResolutionStats';
  import { useTechnicalOverlay } from '@/hooks/useTechnicalOverlay';
  import { useLayerChartData } from '@/hooks/useLayerChartData';
  import { meetingStore } from '@/store/meeting';
  import { useBitrateInfo } from '@/hooks/useBitrateInfo';

  export default defineComponent({
    name: 'LocalVideoSlot',
    components: {
      MediaSlotReactions,
      UserVideoMock,
      UsersOverflowMock,
      VideoSlotLabel,
      Button,
      VideoElement,
      TechnicalLayout,
    },
    props: {
      tile: {
        type: Object as PropType<RenderVideoStore>,
        required: true,
      },
    },
    setup(props) {
      const videoSlotSize = computed(() =>
        getVideoSlotSize({
          width: props.tile.width,
          height: props.tile.height,
        })
      );
      const mirrorStore = useStore($mirrorStore);
      const devices = useStore($devices);
      const sharing = useStore($sharing);
      const hasSeparateSharing = useStore($hasSeparateSharing);
      const videoTrack = computed(
        () => mirrorStore.value.videoPreview || sharing.value.tracks[0]?.track
      );
      const hasLocalVideo = computed(
        () =>
          !!mirrorStore.value.videoPreview || (!hasSeparateSharing.value && sharing.value.isSharing)
      );
      const videoTrackKind = computed(() =>
        sharing.value.isSharing && !mirrorStore.value.videoPreview
          ? sharing.value.tracks[0]?.kind
          : videoTrack.value?.kind
      );
      const localVideoSlot = ref<HTMLDivElement | null>(null);
      const { isMobilePlatform } = useIsMobilePlatform();
      const isHideName = ref(isMobilePlatform);
      const auth = useStore(authStore);
      const myName = computed(() => auth.value?.displayName);
      const users = useStore($users);
      const userAvatar = computed(() => users.value.me?.authUser?.avatar);
      watch(videoTrackKind, (kind) => {
        pinByKind(kind, 'local');
      });

      const { isOverlay } = useTechnicalOverlay();

      const meeting = useStore(meetingStore);
      const remoteId = computed(() => meeting.value.meeting?.remoteId || 'local');
      const { layerChartData } = useLayerChartData(remoteId);

      const { resolutions } = useResolutionStats(toRef(props, 'tile'), videoTrack, ref('local'));
      const { localBitrateInfo, bitrateChartData } = useBitrateInfo(remoteId);

      const debugChartData = computed(() => [...layerChartData.value, ...bitrateChartData.value]);
      const debugTextData = computed(() => {
        const { requested, ...restResolutions } = resolutions.value;

        return {
          ...restResolutions,
          ...localBitrateInfo.value,
        };
      });

      return {
        hasLocalVideo,
        localVideoSlot,
        devices,
        isHideName,
        myName,
        videoTrack,
        videoTrackKind,
        videoSlotSize,
        layerChartData,
        userAvatar,
        isOverlay,
        debugTextData,
        debugChartData,
      };
    },
  });
