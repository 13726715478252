import { CanvasDef } from '@/helpers/layouts';
import { updatePinState } from '@/helpers/pin';
import { RenderVideoStore } from '@/store/endpointMedia/RenderVideoStore';
import { generateTilerArea } from '@/store/webrtc/generateTilerArea';
import { Conference, Endpoint } from '@voximplant/websdk/modules/conference';

export const handleRenderUpdate = (
  canvas: CanvasDef,
  endpoints: RenderVideoStore[],
  meeting: Conference
): RenderVideoStore[] | undefined => {
  if (document.fullscreenElement || canvas.width <= 0 || canvas.height <= 0) {
    return;
  }

  let endpointIds: string[] = [];
  if (endpoints.length) {
    endpointIds = endpoints
      .map(({ stream }) => stream.id)
      // prevent duplicating a local and overflow endpoint
      .filter((id) => !['local', 'overflow'].includes(id));
  } else {
    const endpointsMapArray: Endpoint[] = Object.values(meeting.endpointsMap.value);
    endpointIds = endpointsMapArray.map((element) => element.id);
  }
  updatePinState(endpointIds);

  return generateTilerArea(canvas, endpointIds);
};
