import { guard, sample } from 'effector';
import { EndpointTrackNotifier } from '@voximplant/websdk/modules/conference';
import { ConferenceEvents } from '@/store/conferenceEvents';
import { throttle } from '@/helpers/throttle';
import {
  reorderEndpointMedias,
  VAD_UPDATE_DELAY,
  $mediaSettings,
  enableRemoteVideo,
  makeVideoFullscreen,
  $endpointVideoDisabledByUser,
  disableEndpointVideoByUser,
  enableEndpointVideoByUser,
  openFullScreen,
  $isAllIncomingVideoDisabled,
  requestVideoSizeEvent,
} from '@/store/endpointMedia/index';
import { $endpointManager, updateEndpoints } from '@/store/webrtc/endpoints';
import { meetingStore } from '@/store/meeting';
import { isSharingVideoKind, SHARING_KINDS } from '@/helpers/pin';
import { Endpoint } from '@voximplant/websdk/modules/conference';
import { $canvas, $layoutType } from '@/store/layout';
import { handleRenderUpdate } from '@/store/webrtc/handleRenderUpdate';

ConferenceEvents.vad.watch(
  throttle(({ enabled }) => {
    if (enabled) {
      console.log('endpointMedia store', 'ConferenceEvents.watch', { enabled });
      reorderEndpointMedias();
    }
  }, VAD_UPDATE_DELAY)
);

$mediaSettings
  .on(enableRemoteVideo, (store, { endpointId, active }) => {
    console.log('$mediaSettings', `enableRemoteVideo active is ${active}`);
    console.log('$mediaSettings', 'enableRemoteVideo for user', store[endpointId]);
    console.log('$mediaSettings', 'enableRemoteVideo get store', store);
    for (const mid in store[endpointId]) {
      if (store[endpointId][mid].kind.includes('video')) store[endpointId][mid].active = active;
    }
    console.log('$mediaSettings', 'enableRemoteVideo return', { ...store });
    return { ...store };
  })
  .on(openFullScreen, (store, endpointId) => {
    const videoElement = document.getElementById(endpointId);
    if (!videoElement) return;

    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen();
      // @ts-expect-error safari browser specific
    } else if (videoElement.webkitRequestFullscreen) {
      videoElement.style.transform = 'scaleX(1)';
      // @ts-expect-error safari browser specific
      videoElement.webkitRequestFullscreen(); /* for Safari */
    } else {
      console.error(
        `$mediaSettings`,
        'failed to open full screen - browser dont support this feature'
      );
      return;
    }

    const onLeaveFullScreen = () => {
      if (document.fullscreenElement) return;
      requestVideoSizeEvent({
        endpointId,
        width: videoElement.scrollWidth,
        height: videoElement.scrollHeight,
      });
      videoElement.removeEventListener('fullscreenchange', onLeaveFullScreen);
    };

    videoElement.addEventListener('fullscreenchange', onLeaveFullScreen);
    requestVideoSizeEvent({
      endpointId,
      width: 1920,
      height: 1080,
    });
    // TODO: useless?
    makeVideoFullscreen(endpointId);
  })
  .on(requestVideoSizeEvent, (_store, props) => {
    const { endpointId, width, height } = props;
    const meeting = meetingStore.getState().meeting;
    const endpoint = meeting?.endpointsMap.value[endpointId];
    if (endpoint) {
      console.log('$mediaSettings', 'request video size', props);
      endpoint?.requestVideoSize(width, height);
    } else {
      console.warn('$mediaSettings', 'failed to request video size - no endpoint found', props);
    }
  });

$mediaSettings.watch((store) => {
  console.log('$mediaSettings', 'watch', store);
});

$endpointVideoDisabledByUser
  .on(disableEndpointVideoByUser, (store, endpointIds) => {
    if (!endpointIds) return;
    return store.concat(endpointIds);
  })
  .on(enableEndpointVideoByUser, (store, endpointIds) =>
    store.filter((endpointId) => !endpointIds.includes(endpointId))
  );

const endpointsChanged = sample({
  clock: $endpointManager,
  source: [meetingStore, $endpointVideoDisabledByUser, $endpointManager],
});

endpointsChanged.watch((source) => {
  const [meeting, videoDisabledByUser, tilerArea] = source;

  tilerArea.forEach(({ width, height, stream }) => {
    const endpointId = stream.id;
    const endpoint: Endpoint | undefined = meeting?.meeting?.endpointsMap.value?.[endpointId];

    if (!endpoint || videoDisabledByUser.includes(endpointId)) return;

    const isOverflowed = !width && !height;

    if (isOverflowed && endpoint.videoStatus.value === 'enabled') {
      endpoint.enableVideo(false);
    }

    if (!isOverflowed && endpoint.videoStatus.value === 'disabledByUser') {
      endpoint.enableVideo(true);
    }
  });
});

EndpointTrackNotifier.watchTrackChanges((endpoint, mediaMap) => {
  Object.values(mediaMap).forEach((description) => {
    if (isSharingVideoKind(description.kind) && endpoint.videoStatus.value === 'disabledByUser') {
      enableEndpointVideoByUser([endpoint.id]);
      endpoint.enableVideo(true);
    }
  });
});

const newEndpointIsNotDisabled = guard({
  clock: $endpointManager,
  filter: $isAllIncomingVideoDisabled,
});

sample({
  clock: newEndpointIsNotDisabled,
  source: [$endpointManager, meetingStore, $endpointVideoDisabledByUser],
  fn: ([endpointManager, { meeting }, endpointVideoDisabledByUser]) => {
    const endpointIds: string[] = endpointManager.map(({ stream: { id } }) => id);

    return endpointIds.filter((id) => {
      const endpoint: Endpoint | undefined = meeting?.endpointsMap.value?.[id];
      const isMeetingHasEndpoint = Boolean(endpoint);
      const isEndpointAlreadyDisabled = endpointVideoDisabledByUser.includes(id);
      const isSharingEndpoint = SHARING_KINDS.includes(endpoint?.video?.kind || '');

      return isMeetingHasEndpoint && !isEndpointAlreadyDisabled && !isSharingEndpoint;
    });
  },
  target: disableEndpointVideoByUser,
});

sample({
  clock: [$canvas, $layoutType],
  source: [$endpointManager, $canvas, meetingStore],
  fn: ([endpoints, canvas, { meeting }]) => {
    if (!meeting) return;

    return handleRenderUpdate(canvas, endpoints, meeting);
  },
  target: updateEndpoints,
});
