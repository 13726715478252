import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b4198d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "setting-button" }
const _hoisted_2 = { class: "content-wrap" }
const _hoisted_3 = { class: "additionally-option" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleOption = _resolveComponent("TitleOption")!
  const _component_Toggle = _resolveComponent("Toggle")!
  const _component_PopUpWithButton = _resolveComponent("PopUpWithButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PopUpWithButton, {
      name: "ic25-settings",
      size: "25px"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_TitleOption, {
            class: "title-option",
            title: _ctx.t('settings.option')
          }, null, 8, ["title"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toggles, (toggle) => {
            return (_openBlock(), _createBlock(_component_Toggle, {
              class: "toggle-option",
              label: toggle,
              isLabelFirst: false,
              size: "s",
              modelValue: true
            }, null, 8, ["label"]))
          }), 256))
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Toggle, {
            class: "toggle-option last",
            label: _ctx.t('settings.technical'),
            isLabelFirst: false,
            size: "s"
          }, null, 8, ["label"])
        ])
      ]),
      _: 1
    })
  ]))
}