import { convertToKilobits } from '@/store/debug-info/convertToKilobits';
import { uncamelcase } from '@/helpers/uncamelcase';
import { ChartData } from '@/hooks/useTechnicalOverlay';
import { $debugInfo, pickDebugInfo } from '@/store/debug-info';
import { useStore } from 'effector-vue/composition';
import { computed, Ref } from 'vue';

// const formatBitrate = (bitrate: number) => `${convertToKilobits(bitrate)} kb/s`;

interface BitrateChartData {
  bitrate: number;
  timestamp: number;
}

export const useBitrateInfo = (endpointId: Ref<string>) => {
  const debugInfo = useStore($debugInfo);

  const incomingBitrate = computed(() =>
    pickDebugInfo(debugInfo.value, endpointId.value, 'incomingBitrate', 0)
  );
  const outgoingBitrate = computed(() =>
    pickDebugInfo(debugInfo.value, endpointId.value, 'outgoingBitrate', 0)
  );
  const availableOutgoingBitrate = computed(() =>
    pickDebugInfo(debugInfo.value, endpointId.value, 'availableOutgoingBitrate', 0)
  );

  const bitrateRefMap = {
    incomingBitrate,
    outgoingBitrate,
    availableOutgoingBitrate,
  };

  const localBitrateInfo = computed(() =>
    Object.fromEntries(
      Object.entries(bitrateRefMap).map(([key, bitrateRef]) => {
        const lastRecord = bitrateRef.value[bitrateRef.value.length - 1];
        const lastBitrate = lastRecord?.[0] || 0;
        return [key, convertToKilobits(lastBitrate)];
      })
    )
  );

  const bitrateChartData = computed<ChartData<BitrateChartData>[]>(() =>
    Object.entries(bitrateRefMap).map(([name, bitrateRef]) => ({
      label: uncamelcase(name),
      chart: {
        records: bitrateRef.value.map(([bitrate, timestamp]) => ({
          bitrate: convertToKilobits(bitrate),
          timestamp,
        })),
        lines: [
          {
            xName: 'timestamp',
            yName: 'bitrate',
            color: 'var(--sui-green-500)',
          },
        ],
      },
    }))
  );

  return { localBitrateInfo, bitrateChartData };
};
