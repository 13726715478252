import { lastTimeVideoSizeRequested } from '@/helpers/videoSize';
import { RenderVideoStore } from '@/store/endpointMedia/RenderVideoStore';
import { meetingStore } from '@/store/meeting';
import { ref, computed, watch, onBeforeUnmount, Ref } from 'vue';
import { useTechnicalOverlay } from '@/hooks/useTechnicalOverlay';
import { updateVideoTrackSizeOfEndpoint } from '@/store/debug-info';

export const useResolutionStats = (
  tile: Ref<RenderVideoStore>,
  videoTrack: Ref<MediaStreamTrack | undefined>,
  endpointId: Ref<string>
) => {
  const tileResolution = computed(() => `${tile.value.width}x${tile.value.height}`);
  const videoResolution = ref('No video');
  const { isOverlay } = useTechnicalOverlay();

  let timerId: number;
  watch(
    isOverlay,
    (isOverLay) => {
      if (isOverLay) timerId = setInterval(() => getVideoQuality(), 200);
      else clearInterval(timerId);
    },
    { immediate: true }
  );
  onBeforeUnmount(() => clearInterval(timerId));

  const getVideoQuality = () => {
    if (videoTrack.value?.getSettings) {
      const { width, height } = videoTrack.value.getSettings();
      if (width && height) {
        videoResolution.value = `${width}x${height}`;
        updateVideoTrackSizeOfEndpoint([endpointId.value, [width, height]]);
      }
    } else {
      videoResolution.value = 'No video';
      updateVideoTrackSizeOfEndpoint([endpointId.value, [0, 0]]);
    }
  };

  const requestedResolution = ref<string>('-');
  watch(
    isOverlay,
    (isOverLay, _, onCleanup) => {
      if (!isOverLay) return;
      const { meeting } = meetingStore.getState();
      const endpoint = meeting?.endpointsMap.value?.[endpointId.value];
      const resolution = endpoint?.resolution;

      const getRequestedResolution = () => {
        if (!resolution?.value) return '-';
        const { width, height } = resolution.value;
        return `${width}x${height} (T: ${lastTimeVideoSizeRequested.value})`;
      };
      const updateRequestedResolution = () => {
        requestedResolution.value = getRequestedResolution();
      };
      resolution?.watch(updateRequestedResolution);
      onCleanup(() => resolution?.unwatch(updateRequestedResolution));
      updateRequestedResolution();
    },
    { immediate: true }
  );

  const resolutions = computed(() => ({
    tile: tileResolution,
    got: videoResolution,
    requested: requestedResolution,
  }));

  return {
    resolutions,
  };
};
