import { useSimulcastLayerStats } from '@/hooks/useSimulcastLayerStats';
import { convertToKilobits } from '@/store/debug-info/convertToKilobits';
import { Ref, computed } from 'vue';

type LayerRid = 'l' | 'm' | 'h';

interface LayerPointData {
  height: number;
  bitrate: number;
  timestamp: number;
}

type HistoryByLayerMap = Record<LayerRid, LayerPointData[]>;

type LayerDataEntry = [rid: string, pointData: LayerPointData[]];

export const useLayerChartData = (endpointId: Ref<string>) => {
  const { layerHistoryRecords } = useSimulcastLayerStats(endpointId);

  const layerDataMap = computed(() => {
    const ridMap: HistoryByLayerMap = {
      h: [],
      m: [],
      l: [],
    };

    layerHistoryRecords.value.forEach(([layers, timestamp]) => {
      layers.forEach(({ height, rid, bitrate }) => {
        if (rid) {
          ridMap[rid as LayerRid].push({
            height: height || 0,
            timestamp,
            bitrate: convertToKilobits(bitrate),
          });
        }
      });
    });

    return ridMap;
  });

  const layerChartData = computed(() => {
    return Object.entries(layerDataMap.value).flatMap(([rid, records]) => {
      const lastRecord = records?.[records?.length - 1];
      return [
        {
          label: `${rid} height & bitrate (${lastRecord?.height || 'none'})`,
          chart: {
            records,
            lines: [
              {
                xName: 'timestamp',
                yName: 'height',
                color: 'var(--sui-blue-500)',
              },
              {
                xName: 'timestamp',
                yName: 'bitrate',
                color: 'var(--sui-green-500)',
              },
            ],
          },
        },
      ];
    });
  });

  return { layerChartData };
};
