import { $transceiverStats } from '@/store/statistics';
import { CollectDebugInfoFn, LayerReport } from './types';

export const collectSimulcastLayersInfo: CollectDebugInfoFn = () => {
  const layerReports: LayerReport[] = $transceiverStats.getState().map((stat) => ({
    rid: stat.rid,
    bitrate: stat.bitrate,
    framesPerSecond: stat.framesPerSecond,
    width: stat.width,
    height: stat.height,
    qualityLimitationReason: stat.qualityLimitationReason,
  }));

  return {
    layers: layerReports,
  };
};
