
  import { computed, defineComponent } from 'vue';
  import { Button, Icon, Typography } from '@voximplant/spaceui';
  import { useI18n } from 'vue-i18n';
  import { $drawer, closeDrawer } from '@/store/drawer';
  import {
    $endpointVideoDisabledByUser,
    disableEndpointVideoByUser,
    enableEndpointVideoByUser,
    openFullScreen,
  } from '@/store/endpointMedia';
  import { useStore } from 'effector-vue/composition';
  import { Conference, meetingStore } from '@/store/meeting';
  import { Endpoint } from '@voximplant/websdk/modules/conference';

  export default defineComponent({
    name: 'UserSettings',
    components: {
      Button,
      Icon,
      Typography,
    },

    setup() {
      const { t } = useI18n();
      const drawer = useStore($drawer);
      const videoDisabledByUser = useStore($endpointVideoDisabledByUser);
      const meeting = meetingStore.getState().meeting as Conference;
      const endpoint: Endpoint = meeting?.endpointsMap.value[drawer.value.options?.endpointId];
      const userName = endpoint?.displayName.value;
      const headerText = computed(() => {
        return userName
          ? t('menuList.userSettings') + ` (${userName})`
          : t('menuList.userSettings');
      });

      const listUserMenu = [
        {
          icon: 'ic25-full-screen',
          title: t('speakerMenuList.fullScreen'),
          click: (): void => {
            openFullScreen(drawer.value.options?.endpointId);
          },
        },
      ];

      const isVideoDisabled = computed(() =>
        Boolean(videoDisabledByUser.value.includes(endpoint.id))
      );
      const toggleRemoteVideo = () => {
        if (!isVideoDisabled.value) {
          disableEndpointVideoByUser([endpoint.id]);
        } else {
          enableEndpointVideoByUser([endpoint.id]);
        }
        endpoint?.enableVideo(!isVideoDisabled.value);
      };
      const listMenu = computed(() => {
        let toggle = isVideoDisabled.value ? 'On' : 'Off';
        const activeList = [...listUserMenu];
        if (endpoint.id !== 'local' && (endpoint.hasVideo || isVideoDisabled.value)) {
          activeList.push({
            icon: `ic25-video-${toggle.toLowerCase()}`,
            title: t(`speakerMenuList.toggleRemoteVideo${toggle}`),
            click: toggleRemoteVideo,
          });
        }
        return activeList;
      });

      return {
        t,
        closeDrawer,
        listMenu,
        headerText,
      };
    },
  });
