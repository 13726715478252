
  import { defineComponent } from 'vue';
  import { Button, Typography } from '@voximplant/spaceui';
  import { useI18n } from 'vue-i18n';
  import { closePopup } from '@/store/popup';
  import { requestStartRecording, startRecording } from '@/store/recording/index.ts';

  export default defineComponent({
    name: 'PopUpNotifyRecording',
    components: {
      Typography,
      Button,
    },
    setup() {
      const { t } = useI18n();
      const startRecordingAndClosePopup = () => {
        requestStartRecording();
        closePopup();
      };
      return {
        t,
        closePopup,
        startRecording,
        startRecordingAndClosePopup,
      };
    },
  });
