
  import { Button } from '@voximplant/spaceui';
  import Tooltip from '@/components/info/Tooltip.vue';
  import { computed, defineComponent, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { reactionsStore, toggleHand } from '@/store/reactions';
  import { useStore } from 'effector-vue/composition';
  import { Platform, userPlatform } from '@/helpers/platform';
  import { getShortcuts } from '@/helpers/shortcuts';

  export default defineComponent({
    name: 'riseHand',
    components: {
      Button,
      Tooltip,
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const { t } = useI18n();
      const reaction = useStore(reactionsStore);
      const btnHover = ref(false);
      const tooltipText = computed(() =>
        reaction.value.handRaise ? t('buttons.lowerHand') : t('buttons.raiseHand')
      );
      const shortcuts = getShortcuts(userPlatform === Platform.apple);
      const handTooltip = computed(() => tooltipText.value + ` (${shortcuts.hand.key})`);
      const btnColor = computed(() => {
        if (btnHover.value && !reaction.value.handRaise) return 'var(--sui-purple-500)';
        else if (reaction.value.handRaise) return 'var(--white)';
        else return 'var(--sui-gray-700)';
      });
      return {
        t,
        reaction,
        toggleHand,
        tooltipText,
        btnColor,
        btnHover,
        handTooltip,
      };
    },
  });
