
  import { defineComponent } from 'vue';
  import { Button, Typography, Notification } from '@voximplant/spaceui';
  import { setUserDecline } from '@/store/popup';
  import { useStore } from 'effector-vue/composition.cjs';
  import { $notificationStack, deleteNotification } from '@/store/modal';
  import { useI18n } from 'vue-i18n';
  import { vFocusTrap } from '@/helpers/trapFocusElements';
  import { approve } from '@/store/users';

  export default defineComponent({
    name: 'JoinNotificationList',
    components: {
      Notification,
      Typography,
      Button,
    },
    directives: {
      'focus-trap': vFocusTrap,
    },
    setup() {
      const { t } = useI18n();
      const notificationStack = useStore($notificationStack);

      return {
        t,
        approve,
        setUserDecline,
        deleteNotification,
        notificationStack,
      };
    },
  });
