import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-279baffd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "call-panel" }
const _hoisted_2 = {
  key: 0,
  class: "panel-mobile"
}
const _hoisted_3 = {
  key: 1,
  class: "panel"
}
const _hoisted_4 = { class: "setting-wrap" }
const _hoisted_5 = { class: "video-settings" }
const _hoisted_6 = { class: "user-settings" }
const _hoisted_7 = { class: "reaction-tooltip-wrap button" }
const _hoisted_8 = { class: "reaction-tooltip" }
const _hoisted_9 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharingButton = _resolveComponent("SharingButton")!
  const _component_RaiseHand = _resolveComponent("RaiseHand")!
  const _component_Button = _resolveComponent("Button")!
  const _component_MeetingInfoWithPopup = _resolveComponent("MeetingInfoWithPopup")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_AudioButton = _resolveComponent("AudioButton")!
  const _component_VideoButton = _resolveComponent("VideoButton")!
  const _component_ReactionButton = _resolveComponent("ReactionButton")!
  const _component_MenuButton = _resolveComponent("MenuButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMobileScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.canScreen())
            ? (_openBlock(), _createBlock(_component_SharingButton, {
                key: 0,
                class: "icon"
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_RaiseHand, { class: "rise-hand-button" }),
          _createVNode(_component_Button, {
            class: "icon",
            size: "l",
            mode: "outlined",
            icon: { spriteUrl: "/image/videoconf-icons.svg", name: _ctx.iconMicName, color: "--sui-gray-700" },
            disabled: false,
            iconOnly: "",
            onClick: _ctx.toggleAudio
          }, null, 8, ["icon", "onClick"]),
          _createVNode(_component_Button, {
            class: "icon",
            size: "l",
            mode: "outlined",
            icon: { spriteUrl: "/image/videoconf-icons.svg", name: _ctx.iconCamName, color: "--sui-gray-700" },
            disabled: false,
            iconOnly: "",
            onClick: _ctx.toggleVideo
          }, null, 8, ["icon", "onClick"]),
          _createVNode(_component_Button, {
            class: "icon",
            mode: "alert",
            size: "l",
            icon: { spriteUrl: '/image/videoconf-icons.svg', name: 'ic25-leave-call-white'},
            onClick: _ctx.leaveConference
          }, null, 8, ["icon", "onClick"]),
          _createVNode(_component_Button, {
            class: _normalizeClass(["icon", _ctx.usersButtonClasses]),
            size: "l",
            mode: "flat",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleOpenDrawer('settings'))),
            icon: { spriteUrl: '/image/videoconf-icons.svg', name: 'ic25-more', color: '--sui-gray-700' },
            disabled: false,
            iconOnly: ""
          }, null, 8, ["class", "icon"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Tooltip, {
              class: "button",
              text: _ctx.t('buttons.info')
            }, {
              button: _withCtx(() => [
                _createVNode(_component_MeetingInfoWithPopup, {
                  link: _ctx.meetingPath,
                  name: _ctx.meeting.meetingId
                }, null, 8, ["link", "name"])
              ]),
              _: 1
            }, 8, ["text"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Tooltip, {
              class: "button",
              text: _ctx.sharingTooltip
            }, {
              button: _withCtx(() => [
                (_ctx.canScreen())
                  ? (_openBlock(), _createBlock(_component_SharingButton, { key: 0 }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["text"]),
            _createVNode(_component_AudioButton, { class: "btn-icon-chevron" }),
            _createVNode(_component_VideoButton, { class: "btn-icon-chevron" }),
            _createVNode(_component_Tooltip, {
              class: "button",
              text: _ctx.t('buttons.leave')
            }, {
              button: _withCtx(() => [
                _createVNode(_component_Button, {
                  mode: "alert",
                  size: "l",
                  icon: { spriteUrl: '/image/videoconf-icons.svg', name: 'ic25-leave-call-white'},
                  onClick: _ctx.leaveConference
                }, null, 8, ["icon", "onClick"])
              ]),
              _: 1
            }, 8, ["text"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_RaiseHand),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('buttons.reactions')), 1)
              ]),
              _createVNode(_component_ReactionButton)
            ]),
            _createVNode(_component_Tooltip, {
              class: "button",
              text: _ctx.usersTooltip
            }, {
              button: _withCtx(() => [
                _createVNode(_component_Button, {
                  class: _normalizeClass(["notification-dot-join-request", _ctx.usersButtonClasses]),
                  mode: "flat",
                  size: "l",
                  icon: { spriteUrl: '/image/videoconf-icons.svg', name: 'ic25-users', color: _ctx.contactListIconColor},
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleOpenDrawer('contactList'))),
                  ref: "button"
                }, null, 8, ["class", "icon"])
              ]),
              _: 1
            }, 8, ["text"]),
            _createVNode(_component_Tooltip, {
              class: "btn-icon-sq",
              text: _ctx.chatTooltip
            }, {
              button: _withCtx(() => [
                _createVNode(_component_Button, {
                  class: _normalizeClass(["notification-dot-chat", _ctx.chatBtnClass]),
                  mode: "flat",
                  size: "l",
                  icon: { spriteUrl: '/image/videoconf-icons.svg', name: 'ic25-chat', color: _ctx.chatIconColor},
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleOpenDrawer('chat'))),
                  ref: "button"
                }, null, 8, ["class", "icon"])
              ]),
              _: 1
            }, 8, ["text"]),
            _createVNode(_component_Tooltip, {
              text: _ctx.t('buttons.settings')
            }, {
              button: _withCtx(() => [
                _createVNode(_component_MenuButton, { class: "btn-icon-sq" })
              ]),
              _: 1
            }, 8, ["text"])
          ])
        ]))
  ]))
}