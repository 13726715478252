import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-589ca87a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "users-button" }
const _hoisted_2 = { class: "content-wrap" }
const _hoisted_3 = { class: "contact-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleOption = _resolveComponent("TitleOption")!
  const _component_ContactItem = _resolveComponent("ContactItem")!
  const _component_PopUpWithButton = _resolveComponent("PopUpWithButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PopUpWithButton, {
      class: "users",
      name: "ic25-users",
      size: "25px"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_TitleOption, {
            class: "title-option",
            title: "users"
          }),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chatStore.users, (item) => {
              return (_openBlock(), _createBlock(_component_ContactItem, {
                item: item.user.displayName,
                avatar: item.auth.avatar
              }, null, 8, ["item", "avatar"]))
            }), 256))
          ])
        ])
      ]),
      _: 1
    })
  ]))
}