import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-392e20fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sharing-button" }
const _hoisted_2 = { class: "wrap-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_PopUpWithButton = _resolveComponent("PopUpWithButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.sharingState.isSharing)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          class: "sharing_border",
          size: "l",
          mode: "flat",
          icon: { spriteUrl: '/image/videoconf-icons.svg', name: 'ic25-sharing-on', color: '--sui-gray-700', width: '25px', height: '25px'},
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleScreenSharing('none')))
        }, null, 8, ["icon"]))
      : (_openBlock(), _createBlock(_component_PopUpWithButton, {
          key: 1,
          class: "sharing",
          name: "ic25-sharing-off",
          size: "25px",
          buttonMode: "secondary"
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sharingButtons, (item) => {
                return (_openBlock(), _createBlock(_component_Button, {
                  class: "item",
                  mode: "flat",
                  size: "l",
                  onMouseenter: ($event: any) => (_ctx.activeButtonType = item.type),
                  onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeButtonType = '')),
                  onClick: ($event: any) => (_ctx.toggleScreenSharing(item.type)),
                  icon: { spriteUrl: '/image/videoconf-icons.svg', name: _ctx.getIcon(item.type), color: '--sui-gray-700', width: '25px', height: '25px'}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1032, ["onMouseenter", "onClick", "icon"]))
              }), 256))
            ])
          ]),
          _: 1
        }))
  ]))
}