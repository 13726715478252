
  import { defineComponent, PropType, ref } from 'vue';
  import { closeModal } from '@/store/modal';
  import RadioInput from '@/components/inputs/RadioInput.vue';
  import { Icon } from '@voximplant/spaceui';
  import Profile from '@/components/settings/Profile.vue';
  import Audio from '@/components/settings/Audio.vue';
  import Video from '@/components/settings/Video.vue';
  import Shortcuts from '@/components/settings/Shortcuts.vue';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'effector-vue/composition';

  export default defineComponent({
    name: 'GeneralSettingsMenu',
    props: {
      currentTabProp: {
        type: Object as PropType<{ initTab: string }>,
      },
    },
    components: {
      Shortcuts,
      Video,
      Audio,
      Profile,
      RadioInput,
      Icon,
    },
    setup(props) {
      const currentTab = ref(props.currentTabProp?.initTab);
      const { t } = useI18n();
      const listSetting = ref([
        t('settings.profile'),
        t('settings.audio'),
        t('settings.video'),
        t('settings.shortcuts'),
      ]);
      const hiddenModal = () => {
        closeModal();
      };
      return {
        hiddenModal,
        currentTab,
        listSetting,
      };
    },
  });
