
  import { defineComponent } from 'vue';
  import { Button, Toggle } from '@voximplant/spaceui';
  import TitleOption from '@/components/settings/TitleOption.vue';
  import PopUpWithButton from '@/components/popups/PopUpWithButton.vue';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'SettingsButton',
    components: {
      PopUpWithButton,
      TitleOption,
      Button,
      Toggle,
    },
    setup() {
      const { t } = useI18n();
      const toggles = [t('menuList.chat'), t('menuList.privateMessages'), t('menuList.rename')];
      return {
        t,
        toggles,
      };
    },
  });
