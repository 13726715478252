import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68442801"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layouts" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UsersOverflowMock = _resolveComponent("UsersOverflowMock")!
  const _component_LocalVideoSlot = _resolveComponent("LocalVideoSlot")!
  const _component_VideoSlot = _resolveComponent("VideoSlot")!
  const _component_RecordingPanel = _resolveComponent("RecordingPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayEndpoints, (displayEndpoint, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: JSON.stringify(displayEndpoint.stream)
      }, [
        (displayEndpoint.stream.id === 'overflow')
          ? (_openBlock(), _createBlock(_component_UsersOverflowMock, {
              key: 0,
              tile: displayEndpoint,
              onClick: _ctx.clickOverflow,
              style: _normalizeStyle(_ctx.getStylesFrom(displayEndpoint))
            }, null, 8, ["tile", "onClick", "style"]))
          : (displayEndpoint.stream.id === 'local')
            ? (_openBlock(), _createBlock(_component_LocalVideoSlot, {
                key: 1,
                tile: displayEndpoint,
                style: _normalizeStyle(_ctx.getStylesFrom(displayEndpoint))
              }, null, 8, ["tile", "style"]))
            : (displayEndpoint.width)
              ? (_openBlock(), _createBlock(_component_VideoSlot, {
                  key: JSON.stringify(displayEndpoint.stream),
                  index: index,
                  tile: displayEndpoint,
                  endpointId: displayEndpoint.stream.id,
                  style: _normalizeStyle(_ctx.getStylesFrom(displayEndpoint))
                }, null, 8, ["index", "tile", "endpointId", "style"]))
              : _createCommentVNode("", true)
      ], 64))
    }), 128)),
    (_ctx.showRecordingPanel)
      ? (_openBlock(), _createBlock(_component_RecordingPanel, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}