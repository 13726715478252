
  import { defineComponent } from 'vue';
  import { useMouse, usePoints } from 'vue3-charts';

  export default defineComponent({
    name: 'LabelsLayer',
    props: {
      dataKeys: {
        type: Object as () => [string, string],
        required: true,
      },
    },
    setup(props) {
      const { points } = usePoints(props.dataKeys);

      const mouse = useMouse();

      return {
        points,
        mouse,
      };
    },
  });
