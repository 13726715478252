
  import { computed, defineComponent } from 'vue';
  import ButtonStatus from '@/components/chat/ButtonStatus.vue';
  import GuestAvatarMock from '@/components/accounts/GuestAvatarMock.vue';

  export default defineComponent({
    name: 'Message',
    components: {
      ButtonStatus,
      GuestAvatarMock,
    },
    props: {
      name: {
        type: String,
      },
      message: {
        type: String,
      },
      avatar: {
        type: String,
      },
      time: {
        type: String,
      },
      isPrivate: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    setup(props) {
      const htmlSaveMessage = computed(() => {
        return props.message
          ? props.message.replaceAll('<', '&lt;').replaceAll('>', '&gt;').trim()
          : '';
      });
      const withLinkMessage = computed(() =>
        htmlSaveMessage.value
          .split(' ')
          .map((word) =>
            word.replaceAll(/(https?:\/\/.+)|(www\..+)/g, (value) => {
              const url = value.startsWith('www') ? `http://${value}` : value;
              return `<a target="_blank" rel="noreferrer" href="${url}">${value}</a>`;
            })
          )
          .join(' ')
      );

      return {
        withLinkMessage,
      };
    },
  });
