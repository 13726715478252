import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Line = _resolveComponent("Line")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_Chart = _resolveComponent("Chart")!

  return (_openBlock(), _createBlock(_component_Chart, {
    class: "chart-element",
    size: { width: 325, height: 30 },
    data: _ctx.data,
    margin: _ctx.margin,
    direction: _ctx.direction,
    axis: _ctx.axis,
    onMouseenter: _ctx.toggleDotVisibility,
    onMouseleave: _ctx.toggleDotVisibility
  }, {
    layers: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lines, (line) => {
        return (_openBlock(), _createBlock(_component_Line, {
          lineStyle: { stroke: line.color },
          dotStyle: _ctx.getDotStyle(line),
          dataKeys: _ctx.getDataKeys(line),
          type: "normal",
          hideDot: _ctx.hideDot
        }, null, 8, ["lineStyle", "dotStyle", "dataKeys", "hideDot"]))
      }), 256))
    ]),
    widgets: _withCtx(() => [
      _createVNode(_component_Tooltip, {
        color: "var(--sui-gray-700)",
        config: _ctx.tooltipConfig
      }, null, 8, ["config"])
    ]),
    _: 1
  }, 8, ["data", "margin", "direction", "axis", "onMouseenter", "onMouseleave"]))
}