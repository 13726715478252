
  import { computed, defineComponent, onMounted, onBeforeUnmount, ref } from 'vue';
  import { Button } from '@voximplant/spaceui';
  import { generateCheckClickOutside } from '@voximplant/spaceui';
  import Tooltip from '@/components/info/Tooltip.vue';

  export default defineComponent({
    name: 'PopUpWithChevron',
    components: {
      Button,
      Tooltip,
    },
    props: {
      name: {
        type: String,
        default: 'ic25-settings',
        required: false,
      },
      size: {
        type: String,
        default: '25px',
        required: false,
      },
      title: {
        type: String,
        required: false,
      },
      tooltipBtn: {
        type: String,
        required: false,
      },
      tooltipChevron: {
        type: String,
        required: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:media'],
    setup(props, { emit }) {
      const isActive = ref(false);
      const PopUpRef = ref<HTMLElement | null>(null);
      const PopUpButtonRef = ref<HTMLElement | null>(null);
      const checkClickOutside = computed(() =>
        generateCheckClickOutside([PopUpRef, PopUpButtonRef])
      );
      const onClickOutside = (e: MouseEvent) => {
        if (checkClickOutside.value(e)) {
          isActive.value = false;
        }
      };

      const toggleClass = () => {
        isActive.value = !isActive.value;
      };

      const toggleMedia = () => {
        emit('update:media');
      };

      const onKeyDownEsc = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          isActive.value = false;
        }
      };

      onMounted(() => {
        document.addEventListener('click', onClickOutside, { capture: true });
        document.addEventListener('keydown', onKeyDownEsc);
      });
      onBeforeUnmount(() => {
        document.removeEventListener('click', onClickOutside, {
          capture: true,
        });
        document.removeEventListener('keydown', onKeyDownEsc);
      });

      return {
        isActive,
        toggleClass,
        toggleMedia,
        PopUpRef,
        PopUpButtonRef,
      };
    },
  });
