import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7120d5d3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "video-settings" }
const _hoisted_2 = {
  key: 0,
  class: "video-container"
}
const _hoisted_3 = {
  key: 0,
  class: "video",
  ref: "mirrorVideo",
  autoplay: "true",
  playsinline: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.devices.videoDisabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.mirrorStore.videoPreview)
            ? (_openBlock(), _createElementBlock("video", _hoisted_3, null, 512))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Select, {
      class: "select camera",
      modelValue: _ctx.devices.selectedVideoDevice,
      "onUpdate:modelValue": _ctx.updateVideoDevice,
      label: _ctx.t('settings.camera'),
      options: _ctx.devices.videoDevices,
      searchable: false,
      placeholder: _ctx.t('none'),
      disabled: !_ctx.devices.requestDone || _ctx.devices.videoDisabled
    }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "options", "placeholder", "disabled"]),
    _createVNode(_component_Select, {
      class: "select quality",
      modelValue: _ctx.devices.selectedQuality,
      "onUpdate:modelValue": _ctx.updateVideoQuality,
      label: _ctx.t('settings.quality'),
      options: _ctx.videoQuality,
      searchable: false,
      placeholder: _ctx.t('none'),
      disabled: !_ctx.devices.requestDone || _ctx.devices.videoDisabled
    }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "options", "placeholder", "disabled"])
  ]))
}