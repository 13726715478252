
  import { defineComponent } from 'vue';
  import TitleOption from '@/components/settings/TitleOption.vue';
  import PopUpWithButton from '@/components/popups/PopUpWithButton.vue';
  import ContactItem from '@/components/chat/ContactItem.vue';
  import { useStore } from 'effector-vue/composition';
  import { $chatContent } from '@/store/chat/index';

  export default defineComponent({
    name: 'UsersButton',
    components: {
      ContactItem,
      PopUpWithButton,
      TitleOption,
    },
    setup() {
      const chatStore = useStore($chatContent);

      return {
        chatStore,
      };
    },
  });
