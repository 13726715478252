import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a4e75450"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "video-wrapper",
  ref: "videoSlot"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VadBorder = _resolveComponent("VadBorder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VadBorder, { hasVad: _ctx.hasVad }, null, 8, ["hasVad"])
  ], 512))
}