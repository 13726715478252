
  import { computed, defineComponent, PropType, ref } from 'vue';
  import { Chart, Line, Tooltip } from 'vue3-charts';
  import LabelsLayer from '@/components/LabelsLayer.vue';
  import { LineChartData } from '@/hooks/useTechnicalOverlay';

  const __default__ = defineComponent({
    name: 'LineChart',
    components: { Chart, Line, LabelsLayer, Tooltip },
    props: {
      lines: {
        type: Array as PropType<LineChartData[]>,
        required: true,
      },
      height: {
        type: Number,
        default: 20,
      },
      bgcolor: {
        type: String,
        default: 'var(--sui-gray-800)',
      },
      data: {
        type: Array,
        required: true,
      },
    },
    setup(props) {
      const direction = 'horizontal';
      const margin = {
        left: 7,
        top: 7,
        right: 7,
        bottom: 5,
      };
      const getDotStyle = (line: LineChartData) => ({
        strokeWidth: 1,
        stroke: line.color,
        fill: line.color,
      });
      const hideDot = ref(true);
      const toggleDotVisibility = () => (hideDot.value = !hideDot.value);
      const axis = {
        primary: {
          hide: true,
        },
        secondary: {
          hide: true,
          rotate: true,
        },
      };

      const getDataKeys = (line: LineChartData): [string, string] => [line.xName, line.yName];

      const tooltipConfig = computed(() => ({
        timestamp: {
          hide: true,
        },
        ...Object.fromEntries(props.lines.map(({ yName, color }) => [yName, { color }])),
      }));

      return {
        direction,
        margin,
        getDataKeys,
        getDotStyle,
        axis,
        hideDot,
        toggleDotVisibility,
        tooltipConfig,
      };
    },
  });

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "eca86644": (_ctx.bgcolor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__