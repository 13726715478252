import { CollectDebugInfoFn } from '@/services/DebugInfoCollector/types';
import { $devices } from '@/store/devices';

interface DeviceLabelMap {
  videoDeviceLabel: string | null;
  audioDeviceLabel: string | null;
}

const deviceLabelMap: DeviceLabelMap = {
  videoDeviceLabel: null,
  audioDeviceLabel: null,
};

export const collectDeviceInfo: CollectDebugInfoFn = () => {
  const deviceInfo: Partial<DeviceLabelMap> = {};
  const {
    selectedVideoDevice,
    videoDisabled,
    selectedAudioDevice,
    audioEnabled,
  } = $devices.getState();
  const videoDeviceLabel = (videoDisabled ? null : selectedVideoDevice?.label) ?? null;
  const audioDeviceLabel = (audioEnabled ? selectedAudioDevice?.label : null) ?? null;

  if (videoDeviceLabel !== deviceLabelMap.videoDeviceLabel) {
    deviceLabelMap.videoDeviceLabel = videoDeviceLabel;
    deviceInfo.videoDeviceLabel = videoDeviceLabel;
  }
  if (audioDeviceLabel !== deviceLabelMap.audioDeviceLabel) {
    deviceLabelMap.audioDeviceLabel = audioDeviceLabel;
    deviceInfo.audioDeviceLabel = audioDeviceLabel;
  }

  return deviceInfo;
};
