import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ff1b06f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "audio-button" }
const _hoisted_2 = { class: "wrap-audio" }
const _hoisted_3 = { class: "wrap-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleOption = _resolveComponent("TitleOption")!
  const _component_RadioInput = _resolveComponent("RadioInput")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PopUpWithChevron = _resolveComponent("PopUpWithChevron")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PopUpWithChevron, {
      class: "audio",
      name: _ctx.iconMicName,
      "tooltip-btn": _ctx.microphoneTooltip,
      "tooltip-chevron": _ctx.t('buttons.audioSettings'),
      size: "25px",
      "onUpdate:media": _ctx.toggleAudio
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.listMicrophone.length)
            ? (_openBlock(), _createBlock(_component_TitleOption, {
                key: 0,
                class: "title",
                title: _ctx.t('buttons.selectMicrophone')
              }, null, 8, ["title"]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listMicrophone, (microphone) => {
            return (_openBlock(), _createBlock(_component_RadioInput, {
              class: "item",
              item: microphone.label + microphone.kind,
              label: microphone.label,
              checked: _ctx.isAudioActive(microphone),
              onChange: ($event: any) => (_ctx.updateAudioDevice(microphone)),
              name: "microphone"
            }, null, 8, ["item", "label", "checked", "onChange"]))
          }), 256)),
          (_ctx.canUseOutputDevice())
            ? (_openBlock(), _createBlock(_component_TitleOption, {
                key: 1,
                class: "title",
                title: _ctx.t('buttons.selectSpeaker')
              }, null, 8, ["title"]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listSpeaker, (speaker) => {
            return (_openBlock(), _createBlock(_component_RadioInput, {
              class: "item",
              item: speaker.label + speaker.kind,
              label: speaker.label,
              checked: _ctx.isSpeakerActive(speaker),
              onChange: ($event: any) => (_ctx.updateAudioDevice(speaker)),
              name: "speaker"
            }, null, 8, ["item", "label", "checked", "onChange"]))
          }), 256))
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Button, {
            class: "btn-audio-setting",
            mode: "flat",
            size: "l",
            onClick: _ctx.showAudioSettings
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('buttons.audioSettings')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["name", "tooltip-btn", "tooltip-chevron", "onUpdate:media"])
  ]))
}