
  import { computed, defineComponent, PropType, ref, watch } from 'vue';
  import { useStore } from 'effector-vue/composition';
  import { RenderVideoStore } from '@/store/endpointMedia/RenderVideoStore';
  import VadBorder from '@/components/media/VadBorder.vue';
  import { requestVideoSizeEvent } from '@/store/endpointMedia';
  import { $endpointManager } from '@/store/webrtc/endpoints';

  const __default__ = defineComponent({
    name: 'VideoElement',
    components: {
      VadBorder,
    },
    props: {
      tile: {
        type: Object as PropType<RenderVideoStore>,
        required: true,
      },
      videoTrack: {
        type: MediaStreamTrack,
        required: true,
      },
      endpointId: {
        type: String,
        default: 'local',
      },
      kind: {
        type: String,
        default: 'video',
      },
      hasVad: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const videoSlot = ref<HTMLDivElement | null>(null);
      const displayEndpoints = useStore($endpointManager);

      const currentDisplayEndpoint = computed(() =>
        displayEndpoints.value.find((endpoint) => endpoint.stream.id === props.endpointId)
      );

      const appendVideo = () => {
        if (!videoSlot.value) return;
        const newVideo = document.createElement('video');
        if (props.videoTrack) {
          newVideo.setAttribute('id', props.endpointId);
          newVideo.playsInline = true;
          newVideo.srcObject = new MediaStream([props.videoTrack]);
          videoSlot.value.appendChild(newVideo);
          if (newVideo.paused) {
            newVideo.play();
          }
          if (!currentDisplayEndpoint.value) return;
          requestVideoSizeEvent({
            endpointId: props.endpointId,
            width: currentDisplayEndpoint.value.width,
            height: currentDisplayEndpoint.value.height,
          });
        }
      };
      watch(videoSlot, appendVideo);

      const isLocal = computed(() => props.endpointId.includes('local'));
      const isContain = computed(() => isLocal.value || props.kind.includes('screen'));
      const isScaleX = computed(() => isLocal.value && !props.kind.includes('screen'));
      const videoStyles = computed(() => ({
        objectFit: isContain.value ? 'contain' : 'cover',
        transform: isScaleX.value ? 'scaleX(-1)' : 'none',
      }));

      return {
        videoStyles,
        videoSlot,
      };
    },
  });

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "240d2928": (_ctx.videoStyles.objectFit),
  "f4fcbaf4": (_ctx.videoStyles.transform)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__