import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ac9f3db4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reaction-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmojiPanel = _resolveComponent("EmojiPanel")!
  const _component_PopUpWithButton = _resolveComponent("PopUpWithButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PopUpWithButton, {
      class: "reaction",
      name: "ic25-heart",
      size: "25px",
      disabled: _ctx.disabled
    }, {
      content: _withCtx(() => [
        _createVNode(_component_EmojiPanel, { class: "emoji" })
      ]),
      _: 1
    }, 8, ["disabled"])
  ]))
}