import { startDebugFx, stopDebugFx } from '@/store/debug-info';
import { computed, onBeforeUnmount, ref } from 'vue';

const isOverlay = ref(false);

export interface ChartData<T = Record<string, string>> {
  label: string;
  chart: {
    records: T[];
    lines: LineChartData<T>[];
  };
}

export interface LineChartData<T = unknown> {
  xName: T extends unknown ? string : keyof T;
  yName: T extends unknown ? string : keyof T;
  color: string;
}

export const useTechnicalOverlay = () => {
  const toggleOverlay = () => {
    isOverlay.value = !isOverlay.value;
    if (isOverlay.value) startDebugFx();
    else stopDebugFx();
  };

  const onKeyDown = (ev: KeyboardEvent) => {
    if (ev.altKey && ev.shiftKey) {
      switch (ev.code) {
        case 'KeyS':
          toggleOverlay();
          break;
      }
      ev.preventDefault();
      ev.cancelBubble = true;
    }
  };

  const bindListeners = () => {
    document.addEventListener('keydown', onKeyDown);
    onBeforeUnmount(() => {
      document.removeEventListener('keydown', onKeyDown);
      if (isOverlay.value) stopDebugFx();
    });
  };

  return {
    isOverlay,
    bindListeners,
  };
};
