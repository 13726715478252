import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-741e9f5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "message-wrap" }
const _hoisted_4 = { class: "name-wrap" }
const _hoisted_5 = { class: "name-status" }
const _hoisted_6 = { class: "name" }
const _hoisted_7 = { class: "time" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GuestAvatarMock = _resolveComponent("GuestAvatarMock")!
  const _component_ButtonStatus = _resolveComponent("ButtonStatus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.avatar)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "avatar",
          src: _ctx.avatar,
          alt: _ctx.name
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createBlock(_component_GuestAvatarMock, {
          key: 1,
          "user-name": _ctx.name
        }, null, 8, ["user-name"])),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.name), 1),
          (_ctx.isPrivate)
            ? (_openBlock(), _createBlock(_component_ButtonStatus, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.time), 1)
      ]),
      _createElementVNode("p", {
        class: "text",
        innerHTML: _ctx.withLinkMessage
      }, null, 8, _hoisted_8)
    ])
  ]))
}