
  import { computed, defineComponent, isRef, PropType, ref } from 'vue';
  import { Icon, Typography } from '@voximplant/spaceui';
  import LineChart from '@/components/LineChart.vue';
  import { uncamelcase } from '@/helpers/uncamelcase';
  import { ChartData } from '@/hooks/useTechnicalOverlay';
  import { $endpointManager } from '@/store/webrtc/endpoints';
  import { useStore } from 'effector-vue/composition';

  export default defineComponent({
    name: 'TechnicalLayout',
    components: {
      LineChart,
      Icon,
      Typography,
    },
    props: {
      status: {
        type: String,
        default: 'none',
      },
      textData: {
        type: Object,
        required: true,
      },
      chartData: {
        type: Object as PropType<ChartData[]>,
        required: true,
      },
    },
    setup(props) {
      const statusIconColor = computed(() => {
        if (props.status === 'good') return '--sui-green-500';
        else if (props.status === 'normal') return '--sui-orange-500';
        else return '--sui-red-500';
      });

      const convertedTextData = computed(() =>
        props.textData
          ? Object.entries(props.textData).map(([key, data]) => [
              uncamelcase(key),
              isRef(data) ? data.value : data,
            ])
          : []
      );

      const endpointManager = useStore($endpointManager);
      const isShowCharts = ref(endpointManager.value.length < 5);
      const showChartLabel = computed(() => (isShowCharts.value ? 'Hide charts' : 'Show charts'));
      const showChartIcon = computed(() => `ic16-chevron-${isShowCharts.value ? 'down' : 'up'}`);
      const onShowChartsClick = () => {
        isShowCharts.value = !isShowCharts.value;
      };

      return {
        convertedTextData,
        statusIconColor,
        isShowCharts,
        showChartLabel,
        showChartIcon,
        onShowChartsClick,
      };
    },
  });
