
  import { computed, defineComponent, ref, watch, watchEffect } from 'vue';
  import { useStore } from 'effector-vue/composition';
  import { $devices } from '@/store/devices/index';
  import { meetingStore } from '@/store/meeting';

  export default defineComponent({
    name: 'AudioSlot',
    props: {
      endpointId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const audioSlot = ref<HTMLDivElement | null>(null);
      const meeting = useStore(meetingStore);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const endpointMap = meeting.value.meeting?.endpointsMap.value;
      const currentEndpoint = meetingStore.getState().meeting?.endpointsMap.value[props.endpointId];
      const userAudio = ref(endpointMap?.[props.endpointId]?.audio);
      currentEndpoint?.media.watch(() => {
        userAudio.value = currentEndpoint.audio;
      });

      const appendAudioElement = () => {
        const audio = document.createElement('audio');
        audioSlot.value?.appendChild(audio);
        return audio;
      };

      const updateAudioElement = () => {
        if (!audioSlot.value || !userAudio.value?.track) return;

        let audio = audioSlot.value.querySelector('audio') || appendAudioElement();
        audio.srcObject = new MediaStream([userAudio.value.track]);
        audio.load();
        audio.play();
      };

      watchEffect(() => {
        if (userAudio.value && props.endpointId !== 'local') {
          updateAudioElement();
          updateSinkId();
        }
      });

      const devices = useStore($devices);
      const selectedSpeakerDevice = computed(() => devices.value.selectedSpeakerDevices);

      function updateSinkId() {
        audioSlot.value
          ?.getElementsByTagName('audio')[0]
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          ?.setSinkId(selectedSpeakerDevice.value?.value);
      }
      watch([selectedSpeakerDevice, userAudio], updateSinkId);

      return {
        audioSlot,
      };
    },
  });
