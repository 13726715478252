import { sample } from 'effector';
import { authStore } from '@/store/auth';
import { meetingStore } from '@/store/meeting';
import { $endpointManager, priorityByPin } from '@/store/webrtc/endpoints';
import { $hasSeparateSharing } from '@/store/sharing';

sample({
  clock: [$endpointManager],
  source: [meetingStore, authStore],
  fn: ([{ meeting }, { username }]) =>
    priorityByPin.some(
      (endpointId) => meeting?.endpointsMap.value[endpointId]?.userName === username
    ),
  target: $hasSeparateSharing,
});
