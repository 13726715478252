import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e2cd74a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-user-settings" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "list-settings" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "item-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Typography, {
        class: "header-text",
        fontType: "header",
        fontSize: "700",
        fontColor: "--gray900"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.headerText), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Button, {
        class: "done-btn",
        size: "s",
        mode: "flat",
        onClick: _ctx.closeDrawer
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('done')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listMenu, (menuItem) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "item-wrap",
          onClick: menuItem.click
        }, [
          _createVNode(_component_Icon, {
            class: "icon",
            spriteUrl: "/image/videoconf-icons.svg",
            name: menuItem.icon,
            color: "--sui-purple-500"
          }, null, 8, ["name"]),
          _createElementVNode("p", _hoisted_5, _toDisplayString(menuItem.title), 1)
        ], 8, _hoisted_4))
      }), 256))
    ])
  ]))
}