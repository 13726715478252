
  import { useStore } from 'effector-vue/composition';
  import { reactionsStore } from '@/store/reactions';
  import { emojiHand } from '@/store/emojiList';
  import { computed, ComputedRef, defineComponent } from 'vue';

  export default defineComponent({
    name: 'MediaSlotReactions',

    props: {
      endpointId: {
        type: String,
        required: true,
      },
      userName: {
        type: String,
        required: true,
      },
      size: {
        type: String,
        default: 'medium',
      },
    },

    setup(props) {
      const reactions = useStore(reactionsStore);
      let userReactions!: ComputedRef;
      let isHandUp; // list of user Reactions

      if (props.endpointId === 'local') {
        userReactions = computed(() =>
          // get all active reaction key by boolean value in local Reactions
          Object.keys(reactions.value.localReactions).filter(
            (type) => reactions.value.localReactions[type]
          )
        );
        isHandUp = computed(() => reactions.value.handRaise);
      } else {
        // get userName by keys of list users reactions
        const userName: Record<string, any> = computed(() =>
          Object.keys(reactions.value.reactions).find((user) => user.includes(props.userName))
        );
        userReactions = computed(() => reactions.value.reactions[userName.value]);
        isHandUp = computed(() => userReactions.value?.has('hand'));
      }
      const emojiClasses = computed(() => ({
        [`emoji__${props.size}`]: true, // calculate size of emoji text
      }));
      const reactionsClasses = computed(() => ({
        [`reactions__${props.size}`]: true, // calculate position of reactions container
      }));

      return {
        userReactions,
        reactions,
        emojiHand,
        isHandUp,
        emojiClasses,
        reactionsClasses,
      };
    },
  });
