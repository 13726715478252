import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-030b052c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "recording" }
const _hoisted_2 = { class: "buttons-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Typography, {
      class: "text",
      fontType: "text",
      fontSize: "400",
      fontColor: "var(--sui-gray-700)"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('recording-popup.description')), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Button, {
        size: "m",
        mode: "secondary",
        onClick: _ctx.closePopup
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_Button, {
        size: "m",
        mode: "primary",
        onClick: _ctx.startRecordingAndClosePopup
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('start')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}