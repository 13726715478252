type StorageGetter<T> = () => Array<T>;

/*
  "An evicting queue must be configured with a maximum size. Each time an element is added to a full queue, the queue automatically removes its head element. This is different from conventional bounded queues, which either block or reject new elements when full."
*/
export class EvictingQueue<T> extends Array<T> {
  #maxSize: number;

  constructor(maxSize: number, ...items: T[]) {
    super();
    this.#maxSize = maxSize;
    super.push(...items);
  }

  get isFull() {
    return this.length === this.#maxSize;
  }

  get last() {
    return this[this.length - 1];
  }

  push(element: T) {
    if (this.isFull) {
      this.shift();
    }
    return super.push(element);
  }
}
