import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8cd9da6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chat-area" }
const _hoisted_2 = {
  key: 0,
  class: "header"
}
const _hoisted_3 = { class: "title" }
const _hoisted_4 = {
  key: 1,
  class: "panel"
}
const _hoisted_5 = {
  key: 2,
  class: "chat-loading"
}
const _hoisted_6 = {
  key: 0,
  class: "empty-wrap"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "background-text" }
const _hoisted_9 = {
  key: 1,
  class: "messages-wrap",
  ref: "messagesWrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Message = _resolveComponent("Message")!
  const _component_TextArea = _resolveComponent("TextArea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMobileScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Button, {
            class: "back-btn",
            icon: { spriteUrl: '/image/videoconf-icons.svg', name: 'ic25-shape'},
            size: "s",
            mode: "flat",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDrawer({section: 'settings'})))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('back')), 1)
            ]),
            _: 1
          }, 8, ["icon"]),
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.t('menuList.chat')), 1)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isChatLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.isChatLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_Spinner)
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
          (!_ctx.chatList.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("img", {
                  class: "background-image",
                  src: require('../../assets/images/no-messages-files-background.png')
                }, null, 8, _hoisted_7),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t('chat.noMessage')), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chatList, (item) => {
                  return (_openBlock(), _createBlock(_component_Message, {
                    name: item.name,
                    time: item.time,
                    avatar: item.avatar,
                    message: item.message,
                    isPrivate: false
                  }, null, 8, ["name", "time", "avatar", "message"]))
                }), 256))
              ], 512))
        ], 64)),
    _createVNode(_component_TextArea)
  ]))
}