
  import { computed, defineComponent, ref } from 'vue';
  import { Button, Checkbox, Table } from '@voximplant/spaceui';
  import { getShortcuts } from '@/helpers/shortcuts';
  import { Platform, userPlatform } from '@/helpers/platform';

  export default defineComponent({
    name: 'Shortcuts',
    components: {
      Button,
      Table,
      Checkbox,
    },
    setup() {
      const shortcuts = getShortcuts(userPlatform === Platform.apple);
      const shortcutsValues = computed(() => Object.values(shortcuts));
      const columns = ref([
        {
          title: 'Description',
          field: 'description',
        },
        {
          title: 'Shortcut',
          field: 'shortcut',
        },
      ]);

      const tableOfShortcuts = computed(() => {
        return shortcutsValues.value.map((shortcut) => ({
          description: shortcut.title,
          shortcut: shortcut.key,
        }));
      });
      return {
        tableOfShortcuts,
        columns,
      };
    },
  });
