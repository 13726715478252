import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createBlock(_component_Tooltip, {
    class: "button",
    disabled: _ctx.disabled,
    text: _ctx.handTooltip
  }, {
    button: _withCtx(() => [
      _createVNode(_component_Button, {
        class: _normalizeClass(["hand", {'disabled': _ctx.disabled}]),
        mode: _ctx.reaction.handRaise ? 'primary' : 'flat',
        disabled: _ctx.disabled,
        icon: { spriteUrl: '/image/videoconf-icons.svg', name: 'ic25-hand', color: _ctx.btnColor,  width: '25px', height: '25px'},
        size: "l",
        onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.btnHover = true)),
        onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.btnHover = false)),
        onClick: _ctx.toggleHand
      }, null, 8, ["mode", "disabled", "class", "icon", "onClick"])
    ]),
    _: 1
  }, 8, ["disabled", "text"]))
}