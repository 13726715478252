import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TechnicalLayout = _resolveComponent("TechnicalLayout")!
  const _component_VideoElement = _resolveComponent("VideoElement")!
  const _component_UserVideoMock = _resolveComponent("UserVideoMock")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_Button = _resolveComponent("Button")!
  const _component_VideoSlotLabel = _resolveComponent("VideoSlotLabel")!
  const _component_MediaSlotReactions = _resolveComponent("MediaSlotReactions")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["videoSlot", _ctx.videoSlotClasses]),
    ref: "containerElement",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickMediaSlot && _ctx.onClickMediaSlot(...args)))
  }, [
    (_ctx.isOverlay)
      ? (_openBlock(), _createBlock(_component_TechnicalLayout, {
          key: 0,
          "text-data": _ctx.textData,
          "chart-data": _ctx.chartData
        }, null, 8, ["text-data", "chart-data"]))
      : _createCommentVNode("", true),
    (_ctx.hasVideo)
      ? (_openBlock(), _createBlock(_component_VideoElement, {
          key: 1,
          tile: _ctx.tile,
          endpointId: _ctx.endpointId,
          kind: _ctx.userVideo?.kind,
          videoTrack: _ctx.userVideo?.track,
          hasVad: _ctx.hasVad
        }, null, 8, ["tile", "endpointId", "kind", "videoTrack", "hasVad"]))
      : (_openBlock(), _createBlock(_component_UserVideoMock, {
          key: 2,
          class: "video-mock",
          size: _ctx.videoSlotSize,
          name: _ctx.displayName,
          avatar: _ctx.userAvatar,
          hasVad: _ctx.hasVad
        }, null, 8, ["size", "name", "avatar", "hasVad"])),
    (_ctx.isVideoDisabledByUserOrServer)
      ? (_openBlock(), _createBlock(_component_Tooltip, {
          key: 3,
          class: "video-disabled-icon",
          text: _ctx.videoDisabledReason,
          position: "right"
        }, {
          button: _withCtx(() => [
            _createVNode(_component_Icon, {
              spriteUrl: "/image/videoconf-icons.svg",
              name: _ctx.videoDisabledStatusIconName,
              color: "--sui-gray-700"
            }, null, 8, ["name"])
          ]),
          _: 1
        }, 8, ["text"]))
      : _createCommentVNode("", true),
    (_ctx.showMobileMoreButton)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 4,
          class: "more-button",
          ref: "speakerMoreBtnElement",
          icon: {spriteUrl: '/image/videoconf-icons.svg', name: 'ic25-more', color: '--sui-white', width: '20px', height: '20px'} ,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleOpenDrawer('userSettings')))
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    (!_ctx.isHideVideoSlotLabel)
      ? (_openBlock(), _createBlock(_component_VideoSlotLabel, {
          key: 5,
          endpointId: _ctx.endpointId,
          showMoreButton: _ctx.showDesktopMoreButton,
          name: _ctx.displayName,
          mute: _ctx.userMute,
          hasVideo: _ctx.hasVideo,
          size: _ctx.videoSlotSize,
          ref: "videoSlotLabelElement"
        }, null, 8, ["endpointId", "showMoreButton", "name", "mute", "hasVideo", "size"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MediaSlotReactions, {
      userName: _ctx.userName,
      endpointId: _ctx.endpointId,
      size: _ctx.videoSlotSize
    }, null, 8, ["userName", "endpointId", "size"])
  ], 2))
}