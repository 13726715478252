
  import { computed, defineComponent } from 'vue';
  import { Button, Icon, Spinner, Typography, Notification } from '@voximplant/spaceui';
  import { useStore } from 'effector-vue/composition.cjs';
  import {
    $notificationState,
    NotificationContent,
    setNotificationState,
  } from '@/store/notification';
  import { $userDecline } from '@/store/popup';
  import { useI18n } from 'vue-i18n';
  import { vFocusTrap } from '@/helpers/trapFocusElements';
  import { $awaitingApprovalList } from '@/store/users';

  export default defineComponent({
    name: 'NotificationBlock',

    components: {
      Notification,
      Icon,
      Typography,
      Spinner,
      Button,
    },
    directives: {
      'focus-trap': vFocusTrap,
    },
    setup() {
      const { t } = useI18n();
      const notificationState = useStore($notificationState);
      const showNotification = computed(() => notificationState.value !== 'none');
      const userDecline = useStore($userDecline);
      const awaitingApprovalList = useStore($awaitingApprovalList);
      const notificationClasses = computed(() => ({
        [`${notificationState.value}-notification`]: true,
      }));
      const notificationText = computed(() => {
        switch (notificationState.value) {
          case 'confirmation': {
            return `${t(`${notificationState.value}.text`)} ${userDecline.value.displayName}?`;
          }
          case 'confirmationAll': {
            return `${t(`${notificationState.value}.text`)} ${
              awaitingApprovalList.value.length
            } participants?`;
          }
          default: {
            return t(`${notificationState.value}.text`);
          }
        }
      });
      const notificationContent = computed(() => ({
        iconName: NotificationContent[notificationState.value].icon,
        mode: NotificationContent[notificationState.value].mode,
        title: t(`${notificationState.value}.title`),
        text: notificationText.value,
        showSpinner: NotificationContent[notificationState.value].showSpinner,
        buttons: NotificationContent[notificationState.value].buttons,
      }));

      return {
        t,
        notificationState,
        showNotification,
        notificationClasses,
        notificationContent,
        setNotificationState,
      };
    },
  });
