import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f4ef6eaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "audio-settings" }
const _hoisted_2 = { class: "audio-wrap" }
const _hoisted_3 = {
  key: 0,
  class: "audio-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_Sound = _resolveComponent("Sound")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Select, {
        class: "select microphone",
        modelValue: _ctx.devices.selectedAudioDevice,
        "onUpdate:modelValue": _ctx.updateAudioDevice,
        label: _ctx.t('settings.microphone'),
        options: _ctx.devices.audioDevices,
        searchable: false,
        placeholder: "None",
        disabled: !_ctx.devices.requestDone,
        id: "microphone"
      }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "options", "disabled"]),
      _createVNode(_component_Sound)
    ]),
    (_ctx.canUseOutputDevice())
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Select, {
            class: "select",
            modelValue: _ctx.devices.selectedSpeakerDevices,
            "onUpdate:modelValue": _ctx.updateSpeakerDevice,
            label: _ctx.t('settings.speaker'),
            options: _ctx.devices.speakerDevices,
            searchable: false,
            placeholder: "None",
            disabled: !_ctx.devices.requestDone,
            id: "speaker"
          }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "options", "disabled"])
        ]))
      : _createCommentVNode("", true)
  ]))
}