import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6661f758"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "videoSlot",
  ref: "containerElement"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TechnicalLayout = _resolveComponent("TechnicalLayout")!
  const _component_VideoElement = _resolveComponent("VideoElement")!
  const _component_UserVideoMock = _resolveComponent("UserVideoMock")!
  const _component_VideoSlotLabel = _resolveComponent("VideoSlotLabel")!
  const _component_MediaSlotReactions = _resolveComponent("MediaSlotReactions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isOverlay)
      ? (_openBlock(), _createBlock(_component_TechnicalLayout, {
          key: 0,
          "text-data": _ctx.debugTextData,
          "chart-data": _ctx.debugChartData
        }, null, 8, ["text-data", "chart-data"]))
      : _createCommentVNode("", true),
    (_ctx.hasLocalVideo)
      ? (_openBlock(), _createBlock(_component_VideoElement, {
          key: _ctx.videoTrack.id,
          tile: _ctx.tile,
          videoTrack: _ctx.videoTrack,
          endpointId: "local",
          kind: _ctx.videoTrackKind
        }, null, 8, ["tile", "videoTrack", "kind"]))
      : (_openBlock(), _createBlock(_component_UserVideoMock, {
          key: 2,
          class: "video-mock",
          avatar: _ctx.userAvatar,
          size: _ctx.videoSlotSize,
          name: _ctx.myName
        }, null, 8, ["avatar", "size", "name"])),
    _createVNode(_component_VideoSlotLabel, {
      class: "name",
      mute: !_ctx.devices.audioEnabled,
      name: _ctx.myName,
      size: _ctx.videoSlotSize,
      showButton: false
    }, null, 8, ["mute", "name", "size"]),
    _createVNode(_component_MediaSlotReactions, {
      endpointId: "local",
      size: _ctx.videoSlotSize
    }, null, 8, ["size"])
  ], 512))
}