import { sample } from 'effector';
import { createDebugInfoCollector, DebugInfoReport } from '@/services/DebugInfoCollector';
import { collectDeviceInfo } from '@/services/DebugInfoCollector/collectDeviceInfo';
import { collectEndpointCount } from '@/services/DebugInfoCollector/collectEndpointCount';
import { collectMidCount } from '@/services/DebugInfoCollector/collectMidCount';
import { collectSimulcastLayersInfo } from '@/services/DebugInfoCollector/collectSimulcastLayersInfo';
import { Messenger } from '@/services/Messenger';
import {
  $availableOutgoingBitrate,
  $debugInfo,
  $incomingBitrate,
  $outgoingBitrate,
  $videoTrackSizeMap,
  debugInfoEventBus,
  handleDebugInfoFx,
  REPORT_QUEUE_LENGTH,
  startDebugFx,
  stopDebugFx,
  updateAvailableOutgoingBitrate,
  updateIncomingBitrate,
  updateOutgoingBitrate,
  updateVideoTrackSizeOfEndpoint,
} from '@/store/debug-info';
import { collectTimestamp } from '@/services/DebugInfoCollector/collectTimestamp';
import { EndpointReport } from '@/store/debug-info/DebugInfoState';
import { EvictingQueue } from '@/helpers/EvictingQueue';
import { bitrateReducer } from '@/store/debug-info/bitrateReducer';
import { $statistics, VideoTrackSize } from '@/store/statistics';
import { convertToKilobits } from '@/store/debug-info/convertToKilobits';
import { collectBitrates } from '@/services/DebugInfoCollector/collectBitrates';

const collectorCreated = sample({
  clock: debugInfoEventBus.changeCollectingState,
  source: $debugInfo,
  fn({ collector }, isCollectingActive) {
    if (isCollectingActive && collector === null) {
      const collector = createDebugInfoCollector(debugInfoEventBus, [
        collectEndpointCount,
        collectSimulcastLayersInfo,
        collectDeviceInfo,
        collectMidCount,
        collectTimestamp,
        collectBitrates,
      ]);
      collector.start();
      return collector;
    }
  },
});

$debugInfo
  .on(startDebugFx.done, (store) => ({
    ...store,
    isCollectingActive: true,
  }))
  .on(stopDebugFx.done, (store) => ({
    ...store,
    isCollectingActive: false,
  }))
  .on(collectorCreated, (store, collector) => {
    if (!collector) return;

    return {
      ...store,
      collector,
    };
  })
  .on(handleDebugInfoFx.doneData, (store, { endpointId, report }) => {
    if (!store.isCollectingActive) return;
    const previousReport: EndpointReport | undefined = store.endpointReportMap[endpointId];
    const { videoDeviceLabel, audioDeviceLabel, ...debugInfo } = report;
    const evictingQueue =
      previousReport?.reports || new EvictingQueue<DebugInfoReport>(REPORT_QUEUE_LENGTH);

    evictingQueue.push(debugInfo);

    return {
      ...store,
      endpointReportMap: {
        ...store.endpointReportMap,
        [endpointId]: {
          devices: {
            videoDeviceLabel: videoDeviceLabel ?? previousReport?.devices.videoDeviceLabel,
            audioDeviceLabel: audioDeviceLabel ?? previousReport?.devices.audioDeviceLabel,
          },
          reports: evictingQueue,
        },
      },
    };
  })
  .on(debugInfoEventBus.collectDebugInfo, (store, lastReport) => ({
    ...store,
    lastReport,
  }));

handleDebugInfoFx.use(({ endpoint, payload }) => {
  return {
    endpointId: endpoint,
    report: payload,
  };
});

Messenger.watch((message) => {
  if (Messenger.isMessageEvent(message)) {
    if (message.command === 'startCollectDebugInfo') {
      debugInfoEventBus.changeCollectingState(true);
    }

    if (message.command === 'stopCollectDebugInfo') {
      debugInfoEventBus.changeCollectingState(false);
    }

    if (message.command === 'debugInfo') {
      handleDebugInfoFx(message);
    }
  }
});

startDebugFx.use(() => {
  Messenger.send({ command: 'startDebug' });
});

sample({
  clock: startDebugFx.done,
  fn: () => true,
  target: debugInfoEventBus.changeCollectingState,
});

stopDebugFx.use(() => {
  Messenger.send({ command: 'stopDebug' });
});

sample({
  clock: stopDebugFx.done,
  fn: () => false,
  target: debugInfoEventBus.changeCollectingState,
});

debugInfoEventBus.collectDebugInfo.watch((payload) => {
  Messenger.send({ command: 'debugInfo', payload });
});

$incomingBitrate.on(updateIncomingBitrate, bitrateReducer);
$outgoingBitrate.on(updateOutgoingBitrate, bitrateReducer);
$availableOutgoingBitrate.on(updateAvailableOutgoingBitrate, bitrateReducer);

sample({
  clock: $debugInfo,
  source: $statistics,
  fn: ({ lastReport }) => {
    const inboundReportMap = Object.values(lastReport?.inbound || {}).shift();
    const inboundReport = Object.values(inboundReportMap || {}).shift();
    const bitrate = inboundReport?.bitrate || 0;

    return bitrate;
  },
  target: updateIncomingBitrate,
});

sample({
  source: $debugInfo,
  fn: ({ lastReport }) => lastReport?.layers.reduce((acc, layer) => acc + layer.bitrate, 0) || 0,
  target: updateOutgoingBitrate,
});

sample({
  source: $statistics,
  fn: (stats) => {
    const connection = stats.lastReport?.connection || {};
    const availableOutgoingBitrate = Object.values(connection)[0]?.availableOutgoingBitrate || 0;
    return availableOutgoingBitrate;
  },
  target: updateAvailableOutgoingBitrate,
});

$videoTrackSizeMap.on(updateVideoTrackSizeOfEndpoint, (store, [endpointId, size]) => {
  const queue =
    store[endpointId] || new EvictingQueue<[VideoTrackSize, number]>(REPORT_QUEUE_LENGTH);
  const previousSize = queue.last?.[0];

  if (size?.[0] === previousSize?.[0] && size?.[1] === previousSize?.[1]) return;

  queue.push([size, Date.now()]);

  return {
    ...store,
    [endpointId]: queue,
  };
});
