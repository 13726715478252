
  import { computed, defineComponent, ref, watch } from 'vue';
  import CallButtonPanel from '@/components/settings/CallButtonPanel.vue';
  import { Popup, Typography, Notification, Button } from '@voximplant/spaceui';
  import { $drawer } from '@/store/drawer';
  import Drawer from '@/components/Drawer.vue';
  import ContactList from '@/components/chat/ContactList.vue';
  import ChatArea from '@/components/chat/ChatArea.vue';
  import MenuSettings from '@/components/settings/MenuSettings.vue';
  import LayoutsVideo from '@/components/layouts/LayoutsVideo.vue';
  import ChangeLayout from '@/components/ChangeLayout.vue';
  import MobileExpandedSettings from '@/components/settings/MobileExpandedSettings.vue';
  import MobileDownloadFile from '@/components/settings/MobileDownloadFile.vue';
  import { $chatContent } from '@/store/chat/index';
  import { useStore } from 'effector-vue/composition';
  import { debounce } from '@/helpers/debounce';
  import { resizeVideoSlot } from '@/store/layout';
  import { $popup, closePopup, setUserDecline } from '@/store/popup';
  import PopUpWithButton from '@/components/popups/PopUpWithButton.vue';
  import InvitePeople from '@/components/InvitePeople.vue';
  import { useI18n } from 'vue-i18n';
  import AudioSlot from '@/components/media/AudioSlot.vue';
  import { Platform, userPlatform } from '@/helpers/platform';
  import { $reconnecting } from '@/store/meeting';
  import { $endpointManager } from '@/store/webrtc/endpoints';
  import PopUpNotifyRecording from '@/components/popups/PopUpNotifyRecording.vue';
  import { $recording } from '@/store/recording/index.ts';
  import UserSettings from '@/components/settings/UserSettings.vue';
  import LineChart from '@/components/LineChart.vue';
  import { deleteNotification } from '@/store/modal';
  import { $notificationState, setNotificationState } from '@/store/notification';
  import NotificationBlock from '@/components/popups/NotificationBlock.vue';
  import JoinNotificationList from '@/components/popups/JoinNotificationList.vue';
  import { $isTokenNotValid, resetAuthStore, toggleTokenNotValid } from '@/store/auth';
  import router from '@/router';
  import { JwtStorage } from '@/api';

  const RECORDING_NOTIFICATION_SHOW_DURATION = 3000;
  export default defineComponent({
    name: 'LayoutsPanel',
    components: {
      PopUpNotifyRecording,
      AudioSlot,
      Popup,
      Typography,
      PopUpWithButton,
      LayoutsVideo,
      MenuSettings,
      CallButtonPanel,
      Drawer,
      ContactList,
      ChatArea,
      ChangeLayout,
      MobileExpandedSettings,
      MobileDownloadFile,
      InvitePeople,
      Notification,
      Button,
      LineChart,
      UserSettings,
      NotificationBlock,
      JoinNotificationList,
    },
    setup() {
      const { t } = useI18n();
      const endpointManager = useStore($endpointManager);
      const chatContent = useStore($chatContent);
      const popup = useStore($popup);
      const reconnecting = useStore($reconnecting);
      const recording = useStore($recording);
      const notificationState = useStore($notificationState);
      const layoutPanelClasses = computed(() => ({
        background:
          reconnecting.value ||
          notificationState.value === 'confirmationAll' ||
          notificationState.value === 'confirmation',
      }));
      const isTokenNotValid = useStore($isTokenNotValid);
      const reloadPage = () => {
        location.reload();
      };

      watch(recording, () => {
        if (!recording.value) {
          setNotificationState('recording');
          setTimeout(() => setNotificationState('none'), RECORDING_NOTIFICATION_SHOW_DURATION);
        }
      });
      const canvas = ref<HTMLDivElement | null>(null);
      const routeToAuth = () => {
        // call when user token not valid
        JwtStorage.deleteToken(); // delete token in local storage
        resetAuthStore(); // set NoAuth state and delete user info
        router
          .replace({
            name: 'Join', // if the user token is not valid, will be redirected to Signin with a backlink
          })
          .finally(() => {
            closePopup();
            // TODO вынести на получение токена
            toggleTokenNotValid(false); // reset disable approve/dismiss btn flag
          });
      };

      const drawerStore = useStore($drawer);
      const shouldShowAppleLink = computed(() => userPlatform === Platform.apple);

      const layouts = ref<HTMLDivElement | null>(null);
      const handleLayoutsRef = (layoutsVideo: InstanceType<typeof LayoutsVideo> | undefined) => {
        layouts.value = layoutsVideo ? layoutsVideo?.$el : null;
      };

      watch(layouts, () => {
        if (!layouts.value) return;
        const resizeCallback = (element: ResizeObserverEntry[]) => {
          const elementOptions = {
            width: element[0].contentRect.width,
            height: element[0].contentRect.height,
          };
          resizeVideoSlot(elementOptions);
        };
        const resizeObserver = new ResizeObserver(debounce(resizeCallback, 200));
        resizeObserver.observe(layouts.value);
      });

      return {
        t,
        canvas,
        drawerStore,
        chatContent,
        layoutPanelClasses,
        popup,
        endpointManager,
        closePopup,
        reloadPage,
        shouldShowAppleLink,
        routeToAuth,
        recording,
        setUserDecline,
        isTokenNotValid,
        handleLayoutsRef,
        deleteNotification,
      };
    },
  });
