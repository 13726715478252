
  import { computed, defineComponent, ref } from 'vue';
  import ContactItem from '@/components/chat/ContactItem.vue';
  import RadioInput from '@/components/inputs/RadioInput.vue';
  import { closeDrawer, openDrawer } from '@/store/drawer';
  import { Button, Input, Popup } from '@voximplant/spaceui';
  import { useIsMobileScreen } from '@/hooks/isMobile';
  import { getLink } from '@/helpers/link';
  import {
    $awaitingApprovalList,
    $usersInCall,
    $usersOffline,
    approveAllWaitingUsers,
  } from '@/store/users';
  import { useI18n } from 'vue-i18n';
  import { openPopup, toggleDeclineAllUser } from '@/store/popup';
  import InvitePeople from '@/components/InvitePeople.vue';
  import { useStore } from 'effector-vue/composition';
  import { screenInnerHeight } from '@/hooks/useWindowInnerHeight';
  import PopUpWithButton from '@/components/popups/PopUpWithButton.vue';
  import { $isTokenNotValid } from '@/store/auth';

  const __default__ = defineComponent({
    name: 'ContactList',
    components: {
      InvitePeople,
      ContactItem,
      Button,
      Input,
      RadioInput,
      Popup,
      PopUpWithButton,
    },
    setup() {
      const { t } = useI18n();
      const { isMobileScreen } = useIsMobileScreen();
      const link = getLink();
      const dropDownBtnRef = ref<InstanceType<typeof PopUpWithButton> | null>(null);
      const awaitingApprovalList = useStore($awaitingApprovalList);
      const usersInCall = useStore($usersInCall);
      const usersOffline = useStore($usersOffline);
      const isTokenNotValid = useStore($isTokenNotValid);
      const waitingRoomTitle = computed(
        () => `${t('userStatus.inWaitingRoom')} (${awaitingApprovalList.value.length})`
      );
      const inCallTitle = computed(() => `${t('userStatus.inCall')} (${usersInCall.value.length})`);

      const declineAllWaitingUsers = async () => {
        toggleDeclineAllUser(true);
        dropDownBtnRef.value?.toggleClass(null);
      };
      return {
        t,
        link,
        openPopup,
        openDrawer,
        closeDrawer,
        isTokenNotValid,
        isMobileScreen,
        usersInCall,
        usersOffline,
        screenInnerHeight,
        dropDownBtnRef,
        awaitingApprovalList,
        approveAllWaitingUsers,
        declineAllWaitingUsers,
        waitingRoomTitle,
        inCallTitle,
      };
    },
  });

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "390168f7": (_ctx.screenInnerHeight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__