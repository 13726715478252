import { $debugInfo, pickDebugInfo } from '@/store/debug-info';
import { useStore } from 'effector-vue/composition';
import { computed, Ref } from 'vue';

export const useSimulcastLayerStats = (endpointId: Ref<string>) => {
  const debugInfo = useStore($debugInfo);

  const layerHistoryRecords = computed(() =>
    pickDebugInfo(debugInfo.value, endpointId.value, 'layers', [])
  );

  return { layerHistoryRecords };
};
