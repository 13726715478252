
  import { Button, Icon } from '@voximplant/spaceui';
  import { defineComponent, ref, computed, PropType, watch, toRefs } from 'vue';
  import MediaSlotReactions from '@/components/layouts/MediaSlotReactions.vue';
  import { useStore } from 'effector-vue/composition';
  import { useI18n } from 'vue-i18n';
  import { Endpoint } from '@voximplant/websdk/modules/conference';
  import UserVideoMock from '@/components/layouts/UserVideoMock.vue';
  import { $layoutType } from '@/store/layout';
  import { useIsMobilePlatform, useIsMobileScreen } from '@/hooks/isMobile';
  import { Conference, meetingStore } from '@/store/meeting';
  import VideoElement from '@/components/media/VideoElement.vue';
  import { RenderVideoStore } from '@/store/endpointMedia/RenderVideoStore';
  import { watchEndpointVadChanges } from '@/helpers/vad';
  import { $endpointManager } from '@/store/webrtc/endpoints';
  import { isVideoSizeChanged, lastTimeVideoSizeRequested } from '@/helpers/videoSize';
  import TechnicalLayout from '@/components/layouts/TechnicalLayout.vue';
  import { pinByKind } from '@/helpers/pin';
  import { $drawer, closeDrawer, DrawerSection, openDrawer } from '@/store/drawer';
  import VideoSlotLabel from '@/components/layouts/VideoSlotLabel.vue';
  import { getVideoSlotSize } from '@/helpers/getVideoSlotSize';
  import { useResolutionStats } from '@/hooks/useResolutionStats';
  import { useTechnicalOverlay } from '@/hooks/useTechnicalOverlay';
  import { useLayerChartData } from '@/hooks/useLayerChartData';
  import { useVideoResolutionChartData } from '@/hooks/useVideoHeightChartData';
  import { useVIObservable } from '@/hooks/useVIObservable';
  import { $endpointVideoDisabledByUser, requestVideoSizeEvent } from '@/store/endpointMedia';
  import Tooltip from '@/components/info/Tooltip.vue';
  import { $users } from '@/store/users';

  const MOBILE_SHOW_SETTINGS_USER_LIMIT = 5;

  export default defineComponent({
    name: 'VideoSlot',
    props: {
      tile: {
        type: Object as PropType<RenderVideoStore>,
        required: true,
      },
      endpointId: {
        type: String,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
    },
    components: {
      MediaSlotReactions,
      UserVideoMock,
      Button,
      VideoElement,
      VideoSlotLabel,
      TechnicalLayout,
      Icon,
      Tooltip,
    },
    setup(props) {
      const videoSlotSize = computed(() =>
        getVideoSlotSize({
          width: props.tile.width,
          height: props.tile.height,
        })
      );
      const { isMobilePlatform } = useIsMobilePlatform();
      const { isMobileScreen } = useIsMobileScreen();
      const isHideName = ref(false);
      // IMPORTANT: Don't use useStore! It affects VIObservers
      const meeting = meetingStore.getState().meeting as Conference;
      const endpoint: Endpoint = meeting?.endpointsMap.value[props.endpointId];
      const users = useStore($users);
      const currentUser = computed(() =>
        users.value.users.find((userInfo) => userInfo.username === endpoint.userName)
      );
      const userAvatar = computed(() => currentUser.value?.picture);
      const userVideo = ref(endpoint.video);
      const hasVideo = useVIObservable(endpoint.hasVideo);
      // MS can drop last requested video size, so request new one on video stream enabled
      watch(hasVideo, (currentState, prevState) => {
        if (prevState === false && currentState === true) {
          requestVideoSizeEvent({
            endpointId: props.endpointId,
            width: props.tile.width,
            height: props.tile.height,
          });
        }
      });
      const { userName } = endpoint;

      const drawerState = useStore($drawer);
      const handleOpenDrawer = (section: DrawerSection) => {
        if (drawerState.value.opened && drawerState.value.section === section) {
          return closeDrawer();
        }

        openDrawer({ section, options: { endpointId: props.endpointId } });
      };

      // More than five users, user info appear only with a single tap
      const isClicked = ref(false);
      const displayEndpoints = useStore($endpointManager);
      const isUserMoreLimit = computed(
        () => displayEndpoints.value.length > MOBILE_SHOW_SETTINGS_USER_LIMIT
      );
      const isHideVideoSlotLabel = computed(
        () => isMobilePlatform && isUserMoreLimit.value && !isClicked.value
      );
      const showMobileMoreButton = computed(
        () =>
          props.endpointId !== 'local' &&
          isMobileScreen.value &&
          !isHideVideoSlotLabel.value &&
          hasVideo.value
      );
      const showDesktopMoreButton = computed(
        () => props.endpointId !== 'local' && !isMobileScreen.value
      );

      endpoint?.media.watch(() => {
        userVideo.value = endpoint.video;
        const kind = endpoint.video?.kind || '';
        pinByKind(kind, endpoint.id);
      });

      const videoSlotLabelElement = ref<InstanceType<typeof VideoSlotLabel> | null>(null);
      const onClickMediaSlot = (evt: MouseEvent) => {
        if (!isMobilePlatform || videoSlotLabelElement.value?.$el.contains(evt.target as Element))
          return;
        isHideName.value = !isHideName.value;
      };
      const containerElement = ref<HTMLDivElement | null>(null);
      const videoSlot = ref<HTMLDivElement | null>(null);
      const userMute = useVIObservable(endpoint.mute);

      const layoutType = useStore($layoutType);

      const hasVad = useVIObservable(endpoint.vad);
      watchEndpointVadChanges(endpoint);
      const hasDemonstrationBorder = computed(
        () =>
          layoutType.value === 'demonstration' &&
          props.index === 1 && // only for second user
          !hasVad.value // voiceActive border more priority
      );
      const videoSlotClasses = computed(() => ({
        'voice-active': hasVad.value,
        // demonstration_border: hasDemonstrationBorder, // if camera off add component visibility border for demonstration layout
      }));

      const displayName = computed(() => endpoint?.displayName.value || '');

      const currentDisplayEndpoint = computed(() =>
        displayEndpoints.value.find((endpoint) => endpoint.stream.id === props.endpointId)
      );

      watch(
        currentDisplayEndpoint,
        () => {
          if (
            currentDisplayEndpoint.value?.width &&
            currentDisplayEndpoint.value?.height &&
            isVideoSizeChanged(
              props.endpointId,
              currentDisplayEndpoint.value.width,
              currentDisplayEndpoint.value.height
            )
          ) {
            lastTimeVideoSizeRequested.value = new Date().toLocaleTimeString('ru-RU');
            endpoint?.requestVideoSize(
              currentDisplayEndpoint.value.width,
              currentDisplayEndpoint.value.height
            );
          }
        },
        {
          immediate: true,
        }
      );

      const { isOverlay } = useTechnicalOverlay();

      const { endpointId, tile } = toRefs(props);
      const userVideoTrack = computed(() => userVideo.value?.track as MediaStreamTrack);
      const { resolutions } = useResolutionStats(tile, userVideoTrack, endpointId);

      const lastStreamsUpdateReason = ref<string | null | undefined>(undefined);
      endpoint.lastStreamsUpdateReason.watch((reason: null | 'm' | 'a') => {
        lastStreamsUpdateReason.value = reason;
      });

      const textData = computed(() => ({
        ...resolutions.value,
        ...(typeof lastStreamsUpdateReason.value !== 'undefined' && {
          updateReason: String(lastStreamsUpdateReason.value),
        }),
      }));

      const { layerChartData } = useLayerChartData(endpointId);

      const { videoResolutionChartData } = useVideoResolutionChartData(endpointId);

      const chartData = computed(() => [
        ...layerChartData.value,
        ...videoResolutionChartData.value,
      ]);

      const videoDisabledByUser = useStore($endpointVideoDisabledByUser);
      const videoStatus = useVIObservable(endpoint.videoStatus);
      const isVideoDisabledByUserOrServer = computed(
        () =>
          videoStatus.value === 'disabledByServer' ||
          (videoStatus.value === 'disabledByUser' &&
            videoDisabledByUser.value.includes(endpointId.value))
      );

      const videoDisabledStatusIconName = `ic25-video-off`;
      const { t } = useI18n();
      const videoDisabledReason = computed(() =>
        t(`videoDisabledReason.${videoStatus.value === 'disabledByServer' ? 'byServer' : 'byUser'}`)
      );

      return {
        userName,
        containerElement,
        videoSlot,
        userMute,
        videoSlotClasses,
        isHideName,
        videoSlotLabelElement,
        onClickMediaSlot,
        hasVideo,
        userVideo,
        hasVad,
        displayName,
        handleOpenDrawer,
        showMobileMoreButton,
        showDesktopMoreButton,
        isHideVideoSlotLabel,
        videoSlotSize,
        userAvatar,
        isOverlay,
        textData,
        chartData,
        resolutions,
        isVideoDisabledByUserOrServer,
        videoDisabledStatusIconName,
        videoDisabledReason,
      };
    },
  });
