import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-882e1052"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["reactions", _ctx.reactionsClasses])
  }, [
    (_ctx.isHandUp)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["emoji", _ctx.emojiClasses])
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.emojiHand), 1)
        ], 2))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userReactions, (emoji) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["emoji", _ctx.emojiClasses])
      }, [
        (emoji !== 'hand')
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.reactions.emojilist[emoji]), 1))
          : _createCommentVNode("", true)
      ], 2))
    }), 256))
  ], 2))
}