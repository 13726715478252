import { changePriorityByVad, priorityByVad } from '@/store/webrtc/endpoints';

const watchableEndpoints: string[] = [];

const watchEndpointVadChanges = (endpoint: any) => {
  if (watchableEndpoints.includes(endpoint.id)) return;

  endpoint.vad.watch(subscribeOnVadChanges.bind(null, endpoint.id));

  watchableEndpoints.push(endpoint.id);
};

const subscribeOnVadChanges = (endpointId: string, hasVad: boolean) => {
  const speakers = priorityByVad;
  if (hasVad) {
    if (!speakers.includes(endpointId)) {
      changePriorityByVad(speakers.concat(endpointId));
    }
  } else {
    if (speakers.includes(endpointId)) {
      changePriorityByVad(speakers.filter((id) => id !== endpointId));
    }
  }
};

export { watchEndpointVadChanges };
