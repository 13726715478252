import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-718b39db"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Tooltip, {
      text: _ctx.t('chat.privateDescription')
    }, {
      button: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "btn",
          mode: "primary",
          size: "m"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('chat.privateBtn')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["text"])
  ]))
}