import { BitrateStore } from '@/store/debug-info/BitrateStore';

export const bitrateReducer = (store: BitrateStore, bitrate: number): BitrateStore => {
  const { history } = store;
  history.push([bitrate, Date.now()]);

  return {
    history,
    current: bitrate,
  };
};
