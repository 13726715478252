import { sample } from 'effector';
import { Events } from '@voximplant/websdk/modules/conference';
import { meetingStore } from '@/store/meeting';
import { authStore } from '@/store/auth';
import {
  $statistics,
  $transceiverStats,
  addSessionId,
  addStatsSender,
  statsReceived,
} from './index';
import { OutboundVideoStatsReport } from '@/store/statistics/statsTypes';

const LOCAL_VIDEO_MID = '1';

const subscribedOnStatsReceived = sample({
  clock: meetingStore,
  source: $statistics,
  fn: ({ isSubscribedOnStatsReceived }, { meeting }) => {
    if (isSubscribedOnStatsReceived) return;

    if (!meeting) return false;
    meeting.addEventListener(Events.StatsReceived, ({ stats }) => {
      statsReceived(stats);
    });
    return true;
  },
});

$statistics
  .on(addStatsSender, (store, sender) => ({ ...store, sender }))
  .on(addSessionId, (store, sessionId) => ({ ...store, sessionId }))
  .on(meetingStore, (store, { meetingId }) => {
    if (store.meetingId !== meetingId) return { ...store, meetingId };
  })
  .on(authStore, (store, { username }) => {
    if (store.username !== username) return { ...store, username };
  })
  .on(subscribedOnStatsReceived, (store, isSubscribed) => {
    if (typeof isSubscribed === 'undefined') return;

    return {
      ...store,
      isSubscribedOnStatsReceived: isSubscribed,
    };
  })
  .on(statsReceived, (store, stats) => ({
    ...store,
    lastReport: stats,
  }));

$transceiverStats.on(statsReceived, (store, stats) => {
  const pcId = Object.keys(stats.outbound)[0];
  const outboundReportMap = stats.outbound[pcId];

  if (!outboundReportMap || !(LOCAL_VIDEO_MID in outboundReportMap)) return;

  const videoOutboundReport = outboundReportMap[LOCAL_VIDEO_MID];

  return videoOutboundReport as OutboundVideoStatsReport[];
});

// const initStatsSender = sample({
//   clock: $statistics,
//   source: [meetingStore, $statistics],
// });

// initStatsSender.watch(([{ meeting }, { meetingId, username, sessionId, sender }]) => {
//   if (sender) return;
//   if (meeting && meetingId && username && sessionId) {
//     const sender = new StatsSender(meetingId, username, sessionId);
//     meeting.addEventListener(Events.StatsReceived, ({ stats }) => {
//       console.warn('stats', stats);

//       statsReceived(stats);
//       // sender.sendStats(stats);
//     });
//     addStatsSender(sender);
//   }
// });
