
  import { computed, defineComponent, PropType, ref } from 'vue';
  import { Icon, Button } from '@voximplant/spaceui';
  import { useIsMobileScreen } from '@/hooks/isMobile';
  import MeetingInfoWithPopup from '@/components/settings/MeetingInfoWithPopup.vue';
  import PopUpWithButton from '@/components/popups/PopUpWithButton.vue';
  import SettingsButton from '@/components/panel-buttons/SettingsButton.vue';
  import UsersButton from '@/components/panel-buttons/UsersButton.vue';
  import MenuButton from '@/components/panel-buttons/MenuButton.vue';
  import ReactionButton from '@/components/panel-buttons/ReactionButton.vue';
  import VideoButton from '@/components/panel-buttons/VideoButton.vue';
  import AudioButton from '@/components/panel-buttons/AudioButton.vue';
  import SharingButton from '@/components/panel-buttons/SharingButton.vue';
  import Tooltip from '@/components/info/Tooltip.vue';
  import RadioInput from '@/components/inputs/RadioInput.vue';
  import { useRouter } from 'vue-router';
  import { $drawer, closeDrawer, DrawerSection, openDrawer } from '@/store/drawer';
  import { useStore } from 'effector-vue/composition';
  import { endpointEventList } from '@/store/webrtc/endpointManager';
  import { $devices, toggleAudioEvent } from '@/store/devices/index';
  import {
    $mirrorStore,
    MEDIA_BUTTON_DEBOUNCE_GAP,
    toggleMirrorAudioStream,
    toggleMirrorVideoStream,
  } from '@/store/mirrorMedia/index';
  import { canScreen } from '@/helpers/vendor';
  import { useI18n } from 'vue-i18n';
  import { $sharing } from '@/store/sharing/index';
  import { $newMessagesCount } from '@/store/chat';
  import { meetingStore } from '@/store/meeting';
  import RaiseHand from '@/components/panel-buttons/RaiseHand.vue';
  import { getShortcuts } from '@/helpers/shortcuts';
  import { Platform, userPlatform } from '@/helpers/platform';
  import { Events } from '@voximplant/websdk';
  import { debounce } from '@/helpers/debounce';
  import { soundLevel } from '@/helpers/audioWorklet';
  import { $awaitingApprovalList } from '@/store/users';

  interface PanelMode {
    default?: string;
    admin?: string;
  }

  const modes: PanelMode = {
    default: 'default',
    admin: 'admin',
  };

  export default defineComponent({
    name: 'CallButtonPanel',
    components: {
      RaiseHand,
      Tooltip,
      SharingButton,
      AudioButton,
      VideoButton,
      ReactionButton,
      MenuButton,
      UsersButton,
      SettingsButton,
      PopUpWithButton,
      Icon,
      Button,
      MeetingInfoWithPopup,
      RadioInput,
    },
    // mode = admin - admin settings
    // mode = default - default settings
    props: {
      mode: {
        type: String as PropType<keyof PanelMode>,
        default: 'admin',
      },
      link: {
        type: String,
        default: '/image/videoconf-icons.svg',
        required: false,
      },
    },
    setup() {
      const { t } = useI18n();
      const { isMobileScreen } = useIsMobileScreen();
      const router = useRouter();
      const meeting = useStore(meetingStore);
      const devices = useStore($devices);
      const mirrorStore = useStore($mirrorStore);
      const button = ref<HTMLElement | null>(null);
      const drawerState = useStore($drawer);
      const newMessagesCount = useStore($newMessagesCount);

      const meetingPath = window.location.host + '/' + meeting.value.meetingId;
      const shortcuts = getShortcuts(userPlatform === Platform.apple);
      const chatTooltip = computed(() => t('buttons.chat') + ` (${shortcuts.chat.key})`);
      const usersTooltip = computed(() => t('buttons.users') + ` (${shortcuts.participants.key})`);
      const chatBtnClass = computed(() => ({
        'notification-dot': Boolean(newMessagesCount.value),
      }));
      const toggleAudio = () => {
        toggleAudioEvent();
        toggleMirrorAudioStream();
      };
      const toggleVideo = debounce(() => {
        toggleMirrorVideoStream();
      }, MEDIA_BUTTON_DEBOUNCE_GAP);
      const sharingState = useStore($sharing);
      const sharingTooltip = computed(() =>
        sharingState.value.isSharing ? t('buttons.stopScreenShare') : t('buttons.screenShare')
      );

      const iconCamName = computed(() =>
        mirrorStore.value.videoPreview ? 'ic25-video-on' : 'ic25-video-off'
      );

      const iconMicName = computed(() =>
        devices.value.audioEnabled ? `ic25-mic-on-${soundLevel.value}` : 'ic25-mic-off'
      );

      const contactListIconColor = computed(() =>
        drawerState.value.opened && drawerState.value.section === 'contactList'
          ? '--sui-purple-500'
          : '--sui-gray-700'
      );
      const chatIconColor = computed(() =>
        drawerState.value.opened && drawerState.value.section === 'chat'
          ? '--sui-purple-500'
          : '--sui-gray-700'
      );

      const handleOpenDrawer = (section: DrawerSection) => {
        if (drawerState.value.opened && drawerState.value.section === section) {
          return closeDrawer();
        }

        openDrawer({ section });
      };

      const leaveConference = () => {
        const meeting = useStore(meetingStore);
        meeting.value.meeting?.removeEventListener(Events.Disconnected);
        endpointEventList.clearAll();
        router
          .replace({
            name: 'Left',
          })
          .then(() => meetingStore.getState().meeting?.hangup());
      };

      const awaitingApprovalList = useStore($awaitingApprovalList);
      const usersButtonClasses = computed(() => ({
        'notification-dot': Boolean(awaitingApprovalList.value.length),
      }));

      return {
        t,
        devices,
        meeting,
        meetingPath,
        isMobileScreen,
        chatBtnClass,
        modes,
        contactListIconColor,
        chatIconColor,
        button,
        handleOpenDrawer,
        leaveConference,
        iconMicName,
        iconCamName,
        toggleAudio,
        toggleVideo,
        sharingTooltip,
        canScreen,
        chatTooltip,
        usersTooltip,
        usersButtonClasses,
      };
    },
  });
