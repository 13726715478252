
  import { defineComponent, onMounted } from 'vue';
  import LayoutsPanel from '@/components/layouts/LayoutsPanel.vue';
  import { useStore } from 'effector-vue/composition';
  import { AuthState, authStore } from '@/store/auth';
  import { useRoute, useRouter } from 'vue-router';
  import { $drawer, closeDrawer } from '@/store/drawer';
  import '@/store/endpointMedia/init';
  import '@/store/recording/init';
  import '@/store/statistics/init';
  import '@/store/sharing/init';
  import '@/store/debug-info/init';
  import { screenInnerHeight } from '@/hooks/useWindowInnerHeight';

  const __default__ = defineComponent({
    name: 'VideoConference',
    components: {
      LayoutsPanel,
    },
    setup() {
      const router = useRouter();
      const route = useRoute();
      const drawerStore = useStore($drawer);
      const { conference } = route.params;
      const authS = useStore(authStore);
      console.log('VideoConference views', 'authS.value.authState', authS.value.authState);
      if (authS.value.authState === AuthState.NoAuth) {
        if (conference) {
          router.replace({
            name: 'Join',
            params: { conference },
          });
        } else {
          router.replace({
            name: 'Signin',
          });
        }
      }
      onMounted(() => {
        if (drawerStore.value.opened) {
          closeDrawer();
        }
      });
      return {
        authS,
        screenInnerHeight,
      };
    },
  });

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "04f55d6e": (_ctx.screenInnerHeight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__