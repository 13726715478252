import { createEvent, createStore } from 'effector';
import {
  BaseOutboundStatsReport,
  OutboundVideoStatsReport,
  StatsReport,
} from '@/store/statistics/statsTypes';
import { StatsSender } from '@/services/StatsSender';
interface StatisticsStore {
  sessionId?: string;
  meetingId?: string;
  username?: string;
  sender: StatsSender | null;
  isSubscribedOnStatsReceived: boolean;
  lastReport?: StatsReport;
}

type VideoTrackSize = [width: number, height: number];

const $statistics = createStore<StatisticsStore>({
  sender: null,
  isSubscribedOnStatsReceived: false,
});

const addSessionId = createEvent<string>();

const addStatsSender = createEvent<StatsSender>();

const statsReceived = createEvent<StatsReport>();

const $transceiverStats = createStore<OutboundVideoStatsReport[]>([]);

export {
  StatisticsStore,
  VideoTrackSize,
  $statistics,
  $transceiverStats,
  addSessionId,
  addStatsSender,
  statsReceived,
};
