
  import { computed, defineComponent, PropType, ref } from 'vue';
  import { useStore } from 'effector-vue/composition';
  import { useI18n } from 'vue-i18n';
  import { mockName } from '@/helpers/mockName';
  import { $endpointManager } from '@/store/webrtc/endpoints';
  import { meetingStore } from '@/store/meeting';
  import { getVideoSlotSize } from '@/helpers/getVideoSlotSize';
  import { RenderVideoStore } from '@/store/endpointMedia/RenderVideoStore';

  export default defineComponent({
    name: 'UsersOverflowMock',
    props: {
      tile: {
        type: Object as PropType<RenderVideoStore>,
        required: true,
      },
    },
    setup(props) {
      const { t } = useI18n();
      const videoSlotSize = computed(() =>
        getVideoSlotSize({
          width: props.tile.width,
          height: props.tile.height,
        })
      );
      const endpointManager = useStore($endpointManager);
      const overflowUsers = computed(() =>
        endpointManager.value.filter((tile) => !tile.width && !tile.height)
      );
      const meeting = useStore(meetingStore);
      const endpointMap = meeting.value.meeting?.endpointsMap.value;
      const lastUsersName = computed(() => {
        if (!overflowUsers.value?.length) return [];
        let result: string[] = [];
        const last = overflowUsers.value.slice(0, 3); // first 3 users
        if (endpointMap) {
          last.forEach((el) => {
            const endpoint = endpointMap[el.stream.id];
            if (endpoint) result.push(endpoint.displayName.value || '');
          });
        }
        return result;
      });
      const numberOfOtherUsers = computed(() => overflowUsers.value.length);
      const mockElement = ref<HTMLDivElement | null>(null);
      const usersBlockClasses = computed(() => [
        { [`content-block__${videoSlotSize.value}`]: true },
        { 'two-others': lastUsersName.value.length === 2 },
        { 'three-others': lastUsersName.value.length === 3 },
      ]);
      const contentBlockClasses = computed(() => ({
        [`mock-container__${videoSlotSize.value}`]: true,
      }));

      return {
        t,
        mockName,
        lastUsersName,
        usersBlockClasses,
        mockElement,
        contentBlockClasses,
        numberOfOtherUsers,
      };
    },
  });
