import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a29fcd1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile" }
const _hoisted_2 = { class: "edit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_SignInAccount = _resolveComponent("SignInAccount")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SignInAccount, {
        class: "account",
        userName: _ctx.editedDisplayName,
        email: _ctx.auth.email,
        avatar: _ctx.auth.picture,
        isProfile: true
      }, _createSlots({ _: 2 }, [
        (_ctx.isEditing)
          ? {
              name: "displayName",
              fn: _withCtx(() => [
                _createVNode(_component_Input, {
                  class: "profile-display-name-input",
                  modelValue: _ctx.editedDisplayName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editedDisplayName) = $event)),
                  size: "s",
                  onBlur: _ctx.setNewDisplayName
                }, null, 8, ["modelValue", "onBlur"])
              ])
            }
          : undefined
      ]), 1032, ["userName", "email", "avatar"])
    ])
  ]))
}