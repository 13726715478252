import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5df5dc08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu-button" }
const _hoisted_2 = { class: "content-wrap" }
const _hoisted_3 = { class: "item-wrap item-first" }
const _hoisted_4 = { class: "item-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_PopUpWithButton = _resolveComponent("PopUpWithButton")!
  const _component_GeneralSettingsMenu = _resolveComponent("GeneralSettingsMenu")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PopUpWithButton, {
      name: "ic25-more",
      size: "25px"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Button, {
              class: "item",
              mode: "flat",
              size: "l",
              icon: { spriteUrl: '/image/videoconf-icons.svg', name: 'ic25-gear', color: '--sui-gray-700', width: '25px', height: '25px'},
              onClick: _ctx.showModal,
              ref: "isGeneralSettings"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('menuList.generalSettings')), 1)
              ]),
              _: 1
            }, 8, ["icon", "onClick"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listMenu, (item) => {
              return (_openBlock(), _createBlock(_component_Button, {
                class: _normalizeClass(["item", {'disabled': _ctx.isButtonDisable(item.title)}]),
                disabled: _ctx.isButtonDisable(item.title),
                mode: "flat",
                size: "l",
                onClick: item.click,
                icon: { spriteUrl: '/image/videoconf-icons.svg', name: item.icon, color: '--sui-gray-700', width: '25px', height: '25px'}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1032, ["class", "disabled", "onClick", "icon"]))
            }), 256))
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_Modal, null, {
      default: _withCtx((initTab) => [
        _createVNode(_component_GeneralSettingsMenu, { currentTabProp: initTab }, null, 8, ["currentTabProp"])
      ]),
      _: 1
    })
  ]))
}