
  import {
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    onBeforeMount,
    ref,
    watch,
  } from 'vue';
  import { Input, Button, generateCheckClickOutside } from '@voximplant/spaceui';
  import DownloadedFiles from '@/components/decorative-elements/DownloadedFiles.vue';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'MeetingInfoWithPopup',
    components: {
      Input,
      Button,
      DownloadedFiles,
    },
    props: {
      name: {
        type: String,
        default: 'Meeting name',
      },
      link: {
        type: String,
        default: 'videoconf.voximplant.com',
      },
      count: {
        type: Number,
        default: 1,
      },
      isEmpty: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const { t } = useI18n();
      const input = ref(null);
      // const name = t('nameDefault');
      const isActive = ref(false);
      const PopUpRef = ref<HTMLElement | null>(null);
      const PopUpButtonRef = ref<HTMLElement | null>(null);
      const checkClickOutside = computed(() =>
        generateCheckClickOutside([PopUpRef, PopUpButtonRef])
      );
      const onClickOutside = (e: MouseEvent) => {
        if (checkClickOutside.value(e)) {
          isActive.value = false;
        }
      };

      const toggleClass = () => {
        isActive.value = !isActive.value;
      };

      const onKeyDownEsc = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          isActive.value = false;
        }
      };

      let date = new Date(0, 0, 0);
      let seconds = 1000;
      const formatTime = (date: Date) => date.toTimeString().split(' ')[0];
      let time = ref(formatTime(date));

      watch(input, () => {
        const source = document.getElementsByClassName('hint-container');
        if (source.length) source[0].addEventListener('click', () => setTimeout(toggleClass, 600));
      });

      onBeforeMount(() => {
        setInterval(() => {
          date = new Date(date.getTime() + seconds);
          time.value = formatTime(date);
        }, 1000);
      });
      onMounted(() => {
        document.addEventListener('click', onClickOutside, { capture: true });
        document.addEventListener('keydown', onKeyDownEsc);
      });
      onBeforeUnmount(() => {
        document.removeEventListener('click', onClickOutside, {
          capture: true,
        });
        document.removeEventListener('keydown', onKeyDownEsc);
      });

      return {
        t,
        input,
        // name,
        isActive,
        toggleClass,
        PopUpRef,
        PopUpButtonRef,
        time,
      };
    },
  });
