
  import { computed, defineComponent, watch } from 'vue';
  import VideoSlot from '@/components/media/VideoSlot.vue';
  import LocalVideoSlot from '@/components/media/LocalVideoSlot.vue';
  import { $endpointManager, $localEndpointTile, RawOutput } from '@/store/webrtc/endpoints';
  import { useStore } from 'effector-vue/composition';
  import UsersOverflowMock from '@/components/layouts/UsersOverflowMock.vue';
  import { openDrawer } from '@/store/drawer';
  import { useIsMobileScreen } from '@/hooks/isMobile';
  import { RenderVideoStore } from '@/store/endpointMedia/RenderVideoStore';
  import RecordingPanel from '@/components/RecordingPanel.vue';
  import { $recording, $recordStatus } from '@/store/recording/index.ts';
  import { useTechnicalOverlay } from '@/hooks/useTechnicalOverlay';

  export default defineComponent({
    name: 'LayoutsVideo',
    props: {
      videos: {
        type: Array,
        default: () => [],
      },
    },
    components: {
      VideoSlot,
      LocalVideoSlot,
      UsersOverflowMock,
      RecordingPanel,
    },
    setup() {
      const { isMobileScreen } = useIsMobileScreen();
      const displayEndpoints = useStore($endpointManager);
      const localUser = useStore($localEndpointTile);
      const recordStatus = useStore($recordStatus);
      const recording = useStore($recording);
      const showRecordingPanel = computed(
        () => recordStatus.value === 'recording' || recording.value
      );

      const { bindListeners } = useTechnicalOverlay();
      bindListeners();

      const isShowMediaElement = (videos: RawOutput[]) => {
        return videos.length ? videos[0].height > 0 : false;
      };
      const uppUser = () => {
        // todo: function showing hidden endpoints in a circle
      };
      const clickOverflow = () => {
        if (isMobileScreen) openDrawer({ section: 'contactList' });
        else uppUser();
      };
      const isOverflow = computed(
        () => !!displayEndpoints.value.find((element) => !element.width && !element.height)
      );

      const getStylesFrom = (displayEndpoint: RenderVideoStore) => ({
        height: `${displayEndpoint.height}px`,
        width: `${displayEndpoint.width}px`,
        top: `${displayEndpoint.top}px`,
        left: `${displayEndpoint.left}px`,
      });

      return {
        isShowMediaElement,
        displayEndpoints,
        localUser,
        clickOverflow,
        isOverflow,
        getStylesFrom,
        showRecordingPanel,
      };
    },
  });
