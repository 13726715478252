import { CollectDebugInfoFn } from '@/services/DebugInfoCollector/types';
import { $statistics } from '@/store/statistics';

interface MidCountMap {
  endpointWithVideo: number;
  endpointWithAudio: number;
  videoMidCount: number;
  audioMidCount: number;
}

export const collectMidCount: CollectDebugInfoFn = () => {
  const { lastReport } = $statistics.getState();
  if (!lastReport) return {};

  const midCountMap: MidCountMap = {
    endpointWithVideo: 0,
    endpointWithAudio: 0,
    videoMidCount: 0,
    audioMidCount: 0,
  };

  Object.values(lastReport.inbound).forEach((midMap) => {
    let hasAudio = false;
    let hasVideo = false;

    Object.values(midMap).forEach(({ kind }) => {
      midCountMap.videoMidCount += Number(kind === 'video');
      midCountMap.audioMidCount += Number(kind === 'audio');

      if (!hasVideo) hasVideo = kind === 'video';
      if (!hasAudio) hasAudio = kind === 'audio';
    }, [] as string[]);

    midCountMap.endpointWithVideo += Number(hasVideo);
    midCountMap.endpointWithAudio += Number(hasAudio);
  });

  return midCountMap;
};
