
  import { computed, defineComponent } from 'vue';
  import TitleOption from '@/components/settings/TitleOption.vue';
  import PopUpWithChevron from '@/components/popups/PopUpWithChevron.vue';
  import RadioInput from '@/components/inputs/RadioInput.vue';
  import { Button } from '@voximplant/spaceui';
  import { openModal } from '@/store/modal';
  import { useStore } from 'effector-vue/composition';
  import { $devices, setActiveDevices } from '@/store/devices/index';
  import {
    $mirrorStore,
    MEDIA_BUTTON_DEBOUNCE_GAP,
    requestMirrorStream,
    toggleMirrorVideoStream,
  } from '@/store/mirrorMedia/index';
  import { useI18n } from 'vue-i18n';
  import { VideoDeviceInfo } from '@/store/devices/VideoDeviceInfo';
  import { AudioDeviceInfo } from '@/store/devices/AudioDeviceInfo';
  import { Platform, userPlatform } from '@/helpers/platform';
  import { getShortcuts } from '@/helpers/shortcuts';
  import { debounce } from '@/helpers/debounce';
  const devices = useStore($devices);

  export default defineComponent({
    name: 'VideoButton',
    components: {
      RadioInput,
      PopUpWithChevron,
      TitleOption,
      Button,
    },
    setup() {
      const { t } = useI18n();
      const mirrorStore = useStore($mirrorStore);
      const iconCamName = computed(() =>
        mirrorStore.value.videoPreview ? 'ic25-video-on' : 'ic25-video-off'
      );
      const tooltipText = computed(() =>
        mirrorStore.value.videoPreview ? t('buttons.cameraOff') : t('buttons.cameraOn')
      );
      const shortcuts = getShortcuts(userPlatform === Platform.apple);
      const cameraTooltip = computed(() => tooltipText.value + ` (${shortcuts.camera.key})`);
      const listCamera = computed(() => devices.value.videoDevices || []);
      const updateVideoDevice = debounce((e: VideoDeviceInfo) => {
        if (mirrorStore.value.videoPreview) {
          requestMirrorStream({
            selectedAudioDevice: $devices.getState().selectedAudioDevice,
            selectedVideoDevice: e,
          });
        } else {
          setActiveDevices({ selectedVideoDevice: e });
        }
      }, MEDIA_BUTTON_DEBOUNCE_GAP);
      const toggleVideo = debounce(() => {
        toggleMirrorVideoStream();
      }, MEDIA_BUTTON_DEBOUNCE_GAP);
      const showVideoSettings = () => {
        openModal({ opened: true, componentOptions: { initTab: 'Video' } });
      };
      const isActive = (item: AudioDeviceInfo | never) => {
        return item.value === $devices.getState().selectedVideoDevice?.value;
      };

      return {
        t,
        iconCamName,
        cameraTooltip,
        listCamera,
        toggleVideo,
        updateVideoDevice,
        showVideoSettings,
        isActive,
        devices,
      };
    },
  });
