
  import { defineComponent, ref } from 'vue';
  import { Button } from '@voximplant/spaceui';
  import PopUpWithButton from '@/components/popups/PopUpWithButton.vue';
  import { $sharing } from '@/store/webrtc/endpoints';
  import { endpointEventList } from '@/store/webrtc/endpointManager';
  import { SharingType } from '@/store/sharing/index';
  import { useStore } from 'effector-vue/composition.cjs';
  import { Vendor, userAgent } from '@/helpers/vendor';
  import { useI18n } from 'vue-i18n';
  import { openPopup } from '@/store/popup';
  import { debounce } from '@/helpers/debounce';
  import { MEDIA_BUTTON_DEBOUNCE_GAP } from '@/store/mirrorMedia';

  export default defineComponent({
    name: 'SharingButton',
    components: {
      PopUpWithButton,
      Button,
    },
    setup() {
      const { t } = useI18n();
      const activeButtonType = ref('');
      const getIcon = (type: string) => {
        if (type === 'withVideo') {
          return activeButtonType.value === type ? 'ic25-sharing-active' : 'ic25-sharing';
        } else if (type === 'withVideoAndAudio') {
          return activeButtonType.value === type
            ? 'ic25-sharing-with-sound-active'
            : 'ic25-sharing-with-sound';
        } else {
          return 'ic25-sharing'; // default
        }
      };
      const listSharing = [
        {
          icon: 'ic25-sharing',
          title: t('buttons.sharingButtons.withVideo'),
          type: 'withVideo',
        },
        /*{
          icon: 'ic25-screen',
          title: t('buttons.sharingButtons.replace'),
          type: 'replace',
        },*/
      ];
      const sharingState = useStore($sharing);
      const sharingButtons =
        userAgent === Vendor?.chrome
          ? [
              {
                icon: 'ic25-sharing',
                title: t('buttons.sharingButtons.withVideoAndAudio'),
                type: 'withVideoAndAudio',
              },
              ...listSharing,
            ]
          : listSharing;
      const toggleScreenSharing = debounce((type: SharingType) => {
        if (!$sharing.getState().isSharing) {
          endpointEventList.addSharing(type).catch((e: Error) => {
            if (e.message.includes('Permission denied')) {
              openPopup('sharing-permission');
            }
          });
        } else {
          endpointEventList.removeSharing();
        }
      }, MEDIA_BUTTON_DEBOUNCE_GAP);

      return {
        toggleScreenSharing,
        sharingState,
        sharingButtons,
        getIcon,
        activeButtonType,
      };
    },
  });
