
  import { defineComponent, onMounted, ref } from 'vue';
  import { Button, Select } from '@voximplant/spaceui';
  import { $devices, setActiveDevices, setVideoQuality } from '@/store/devices/index';
  import { $mirrorStore, requestMirrorStreamFx } from '@/store/mirrorMedia/index';
  import { useStore } from 'effector-vue/composition';
  import { useI18n } from 'vue-i18n';
  import { VideoDeviceInfo } from '@/store/devices/VideoDeviceInfo';
  import { VideoQuality } from '@/store/devices/VideoQuality';
  const devices = useStore($devices);

  export default defineComponent({
    name: 'Video',
    components: {
      Button,
      Select,
    },
    setup() {
      const { t } = useI18n();
      const mirrorVideo = ref<HTMLVideoElement>();
      const mirrorStore = useStore($mirrorStore);
      const updateVideoDevice = (e: VideoDeviceInfo) => {
        if (mirrorStore.value.videoPreview) {
          requestMirrorStreamFx({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            selectedAudioDevice: devices.value.selectedAudioDevice,
            selectedVideoDevice: e,
          });
        } else {
          setActiveDevices({ selectedVideoDevice: e });
        }
      };
      const videoQuality = Object.values(VideoQuality);
      const updateVideoQuality = (e: { label: string; value: number }) => {
        setVideoQuality(e);
        if (mirrorStore.value.videoPreview) {
          requestMirrorStreamFx({
            selectedAudioDevice: $devices.getState().selectedAudioDevice,
            selectedVideoDevice: $devices.getState().selectedVideoDevice,
          });
        }
      };

      onMounted(() => {
        $mirrorStore.watch((state) => {
          if (state.videoPreview && mirrorVideo.value) {
            mirrorVideo.value.srcObject = new MediaStream([state.videoPreview]);
            mirrorVideo.value?.addEventListener('canplaythrough', () => mirrorVideo.value?.play());
          }
        });
      });

      return {
        t,
        devices,
        mirrorStore,
        mirrorVideo,
        updateVideoDevice,
        updateVideoQuality,
        videoQuality,
      };
    },
  });
