import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a2a76b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "join-approve-notification-wrap" }
const _hoisted_2 = { class: "button-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationStack, (userInfo) => {
      return (_openBlock(), _createBlock(_component_Notification, {
        class: "join-approve-notification",
        title: " ",
        key: userInfo?.vox_user_id,
        onClose: ($event: any) => (_ctx.deleteNotification(userInfo?.vox_user_id))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Typography, {
            class: "user-approve-title",
            fontSize: "16px"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(userInfo?.display_name + ' ' + _ctx.t('join.userApprove.title')), 1)
            ]),
            _: 2
          }, 1024),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Button, {
              mode: "secondary",
              onClick: ($event: any) => (_ctx.setUserDecline({userName: userInfo?.username, displayName: userInfo.display_name}))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('join.userApprove.dismissButton')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_Button, {
              onClick: ($event: any) => (_ctx.approve(userInfo?.username))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('join.userApprove.admitButton')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        _: 2
      }, 1032, ["onClose"]))
    }), 128))
  ]))
}