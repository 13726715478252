import { ChartData } from '@/hooks/useTechnicalOverlay';
import { $videoTrackSizeMap } from '@/store/debug-info';
import { useStore } from 'effector-vue/composition';
import { computed, Ref } from 'vue';

interface VideoHeightChartData {
  height: number;
  timestamp: number;
}

export const useVideoResolutionChartData = (endpointId: Ref<string>) => {
  const videoTrackSizeMap = useStore($videoTrackSizeMap);

  const videoResolutionChartData = computed<ChartData<VideoHeightChartData>[]>(() => [
    {
      label: 'video height',
      chart: {
        records:
          videoTrackSizeMap.value[endpointId.value]?.map(([size, timestamp]) => ({
            height: size[1],
            timestamp,
          })) || [],
        lines: [
          {
            xName: 'timestamp',
            yName: 'height',
            color: 'var(--sui-purple-500)',
          },
        ],
      },
    },
  ]);

  return { videoResolutionChartData };
};
