
  import { computed, defineComponent } from 'vue';
  import { Button, Typography } from '@voximplant/spaceui';
  import { useI18n } from 'vue-i18n';
  import Tooltip from '@/components/info/Tooltip.vue';
  import { $recording, $recordStatus, requestStopRecording } from '@/store/recording/index.ts';
  import { useStore } from 'effector-vue/composition';

  export default defineComponent({
    name: 'RecordingPanel',
    components: {
      Typography,
      Button,
      Tooltip,
    },
    setup() {
      const { t } = useI18n();
      const recordStatus = useStore($recordStatus);
      const recording = useStore($recording);
      const showBtn = computed(() => recordStatus.value === 'recording' && recording.value);
      const classRecordingPanel = computed(() => {
        return showBtn.value ? 'with-button' : 'without-button';
      });
      return {
        t,
        requestStopRecording,
        classRecordingPanel,
        showBtn,
      };
    },
  });
