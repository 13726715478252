import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-671eeee4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contact-list" }
const _hoisted_2 = {
  key: 0,
  class: "header"
}
const _hoisted_3 = {
  key: 0,
  class: "title"
}
const _hoisted_4 = { class: "contact-list-wrap" }
const _hoisted_5 = {
  key: 0,
  class: "in-waiting-room"
}
const _hoisted_6 = { class: "title-wrap" }
const _hoisted_7 = { class: "statusTitle title-text" }
const _hoisted_8 = { class: "content-wrap" }
const _hoisted_9 = { class: "in-call" }
const _hoisted_10 = { class: "statusTitle" }
const _hoisted_11 = {
  key: 1,
  class: "no-active"
}
const _hoisted_12 = { class: "statusTitle" }
const _hoisted_13 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_PopUpWithButton = _resolveComponent("PopUpWithButton")!
  const _component_ContactItem = _resolveComponent("ContactItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMobileScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Button, {
            class: "back-btn",
            icon: { spriteUrl: '/image/videoconf-icons.svg', name: 'ic25-shape'},
            size: "s",
            mode: "flat",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDrawer({section: 'settings'})))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("back")), 1)
            ]),
            _: 1
          }, 8, ["icon"]),
          (_ctx.isMobileScreen)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.t("buttons.users")) + " (" + _toDisplayString(_ctx.usersInCall.length) + ")", 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.awaitingApprovalList.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.waitingRoomTitle), 1),
              (!_ctx.isTokenNotValid && _ctx.awaitingApprovalList.length > 1)
                ? (_openBlock(), _createBlock(_component_PopUpWithButton, {
                    key: 0,
                    name: "ic25-more",
                    size: "16px",
                    "pop-up-width": 148,
                    ref: "dropDownBtnRef"
                  }, {
                    content: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_Button, {
                          class: "popup-btn",
                          size: "m",
                          mode: "flat",
                          width: "fill-container",
                          onClick: _ctx.approveAllWaitingUsers
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("buttons.admitAll")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_Button, {
                          class: "popup-btn",
                          size: "m",
                          mode: "flat",
                          width: "fill-container",
                          onClick: _ctx.declineAllWaitingUsers
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("buttons.declineAll")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ])
                    ]),
                    _: 1
                  }, 512))
                : _createCommentVNode("", true)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.awaitingApprovalList, (user) => {
              return (_openBlock(), _createBlock(_component_ContactItem, {
                displayName: user.display_name,
                key: user,
                userName: user.username,
                endpointId: user,
                avatar: user.picture,
                type: "wait"
              }, null, 8, ["displayName", "userName", "endpointId", "avatar"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.inCallTitle), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersInCall, (user) => {
          return (_openBlock(), _createBlock(_component_ContactItem, {
            id: user?.id,
            displayName: user?.display_name,
            key: user?.vox_user_id,
            userName: user?.username,
            endpointId: user?.vox_user_id,
            avatar: user?.picture,
            type: "inCall"
          }, null, 8, ["id", "displayName", "userName", "endpointId", "avatar"]))
        }), 128))
      ]),
      (_ctx.usersOffline.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.t('userStatus.offline')), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersOffline, (user) => {
              return (_openBlock(), _createBlock(_component_ContactItem, {
                displayName: user?.display_name,
                avatar: user?.picture,
                type: "offline"
              }, null, 8, ["displayName", "avatar"]))
            }), 256))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_Button, {
        class: "copy-link-btn",
        icon: { spriteUrl: '/image/videoconf-icons.svg', name: 'ic25-add-user', color: '--sui-gray-700'},
        width: "fill-container",
        height: "48px",
        mode: "secondary",
        size: "l",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openPopup('invite')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('invitePeople.invite')), 1)
        ]),
        _: 1
      }, 8, ["icon"])
    ])
  ]))
}