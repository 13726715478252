import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a4aa230"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = { class: "button-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Typography = _resolveComponent("Typography")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Notification = _resolveComponent("Notification")!
  const _directive_focus_trap = _resolveDirective("focus-trap")!

  return (_ctx.showNotification)
    ? _withDirectives((_openBlock(), _createBlock(_component_Notification, {
        key: 0,
        mode: _ctx.notificationContent.mode,
        class: _normalizeClass(_ctx.notificationClasses),
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setNotificationState('none')))
      }, {
        default: _withCtx(() => [
          (_ctx.notificationContent.mode === 'error')
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 0,
                name: "ic24-error-fill",
                width: "38",
                height: "38",
                color: "--sui-red-500"
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_Typography, { class: "title" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.notificationContent.title), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            (_ctx.notificationContent.showSpinner)
              ? (_openBlock(), _createBlock(_component_Spinner, {
                  key: 0,
                  size: "m"
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_Typography, {
              class: "text",
              fontSize: "14px",
              fontColor: "var(--gray700)"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.notificationContent.text), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationContent.buttons, (notificationButton) => {
              return (_openBlock(), _createBlock(_component_Button, {
                mode: notificationButton.mode,
                size: notificationButton.size,
                onClick: ($event: any) => (notificationButton.click())
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t(`${_ctx.notificationState}.${notificationButton.text}`)), 1)
                ]),
                _: 2
              }, 1032, ["mode", "size", "onClick"]))
            }), 256))
          ])
        ]),
        _: 1
      }, 8, ["mode", "class"])), [
        [_directive_focus_trap, _ctx.showNotification]
      ])
    : _createCommentVNode("", true)
}