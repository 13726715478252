export const uncamelcase = (str: string): string => {
  const newStr: string[] = [];
  for (const char of str) {
    const charCode = char.charCodeAt(0);
    const isUppercased = charCode <= 90 && charCode >= 64;
    newStr.push(isUppercased ? ` ${char.toLowerCase()}` : char);
  }

  return newStr.join('').trim();
};
