import { GetRecorderState, Messenger, StartRecorder, StopRecorder } from '@/services/Messenger';
import {
  $recording,
  $recordStatus,
  getRecordingStatus,
  requestStartRecording,
  requestStopRecording,
  setRecordStatus,
  startRecording,
  stopRecording,
} from '@/store/recording/index';
import { useStore } from 'effector-vue/composition';
import { authStore } from '@/store/auth';

Messenger.watch((message) => {
  if (Messenger.isMessageEvent(message) && message.command === 'recorderStarted') {
    const { id } = message;
    startRecording(id);
  } else if (Messenger.isMessageEvent(message) && message.command === 'recorderStateChanged') {
    const { state } = message;
    setRecordStatus(state);
  }
});

$recording.on(startRecording, (store, id) => id).on(stopRecording, () => false);
$recordStatus.on(setRecordStatus, (store, state) => state);

requestStartRecording.use(() => {
  const auth = useStore(authStore);
  const payload: StartRecorder = {
    command: 'startRecorder',
    type: 'plain',
    email: auth.value.email,
  };
  return Messenger.send(payload);
});

requestStopRecording.use(() => {
  const recording = useStore($recording);
  if (typeof recording.value !== 'string') return;
  const payload: StopRecorder = { command: 'stopRecorder', id: recording.value };
  return Messenger.send(payload);
});

getRecordingStatus.use(() => {
  const payload: GetRecorderState = { command: 'getRecorderState' };
  Messenger.send(payload);
});

$recording.on(requestStopRecording.doneData, () => {
  stopRecording();
});

getRecordingStatus();
