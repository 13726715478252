
  import { defineComponent } from 'vue';
  import { Button } from '@voximplant/spaceui';
  import Tooltip from '@/components/info/Tooltip.vue';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'ButtonStatus',
    components: {
      Tooltip,
      Button,
    },
    setup() {
      const { t } = useI18n();
      return {
        t,
      };
    },
  });
