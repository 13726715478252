import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d7dbe24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "shortcuts" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Table, {
      data: _ctx.tableOfShortcuts,
      columns: _ctx.columns,
      keyField: "description"
    }, null, 8, ["data", "columns"])
  ]))
}