import { Conference } from '@/store/meeting';
import { $transceiverStats } from '@/store/statistics';
import { $endpointManager } from '@/store/webrtc/endpoints';
import {
  DebugInfoCollectorType,
  DebugInfoCollectorEventBus,
  DebugInfoCollectorFactory,
  DebugInfoReport,
  CollectDebugInfoFn,
} from './types';

const COLLECTING_INTERVAL = 10000;

export class DebugInfoCollector implements DebugInfoCollectorType {
  private collectingTimerId: ReturnType<typeof setTimeout> | null = null;

  constructor(
    private readonly eventBus: DebugInfoCollectorEventBus,
    private readonly collectors: CollectDebugInfoFn[]
  ) {
    eventBus.changeCollectingState.watch((flag) => {
      if (flag) this.start();
      else this.stop();
    });
  }

  start(): void {
    if (this.collectingTimerId) this.stop();
    const startDelay = Math.round(Math.random() * 10) * 1000;

    this.collectingTimerId = setTimeout(() => {
      this.eventBus.collectDebugInfo(this.collect());
      this.enqueueCollecting();
    }, startDelay);
  }

  stop(): void {
    if (!this.collectingTimerId) return;
    clearTimeout(this.collectingTimerId);
    this.collectingTimerId = null;
  }

  private enqueueCollecting() {
    this.collectingTimerId = setTimeout(() => {
      this.eventBus.collectDebugInfo(this.collect());
      this.enqueueCollecting();
    }, COLLECTING_INTERVAL);
  }

  private collect(): DebugInfoReport {
    console.warn('start collecting');

    const report: DebugInfoReport = this.collectors.reduce(
      (report, collector) => Object.assign(report, collector()),
      {} as DebugInfoReport
    );

    return report;
  }
}

const createDebugInfoCollector: DebugInfoCollectorFactory = (eventBus, collectors) =>
  new DebugInfoCollector(eventBus, collectors);

export { createDebugInfoCollector };
export * from './types';
