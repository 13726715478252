import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae7abf8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "mock-container",
  ref: "mockElement"
}
const _hoisted_2 = {
  key: 0,
  class: "count-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["content-block", _ctx.contentBlockClasses])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["users-block", _ctx.usersBlockClasses])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lastUsersName, (userName) => {
          return (_openBlock(), _createElementBlock("p", {
            class: "user",
            key: userName
          }, _toDisplayString(_ctx.mockName(userName)), 1))
        }), 128))
      ], 2),
      (_ctx.numberOfOtherUsers)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.numberOfOtherUsers) + " " + _toDisplayString(_ctx.t('others')), 1))
        : _createCommentVNode("", true)
    ], 2)
  ], 512))
}