
  import { defineComponent } from 'vue';
  import { Button, Select } from '@voximplant/spaceui';
  import Sound from '@/components/decorative-elements/Sound.vue';
  import ToggleWithText from '@/components/toggles/ToggleWithText.vue';
  import Tooltip from '@/components/info/Tooltip.vue';
  import { useStore } from 'effector-vue/composition.cjs';
  import { $devices, selectSpeakerDevice, setActiveDevices } from '@/store/devices/index';
  import {
    $mirrorStore,
    MEDIA_BUTTON_DEBOUNCE_GAP,
    requestMirrorStream,
  } from '@/store/mirrorMedia/index';
  import { useI18n } from 'vue-i18n';
  import { userAgent, Vendor } from '@/helpers/vendor';
  import { AudioDeviceInfo } from '@/store/devices/AudioDeviceInfo';
  import { debounce } from '@/helpers/debounce';
  const devices = useStore($devices);

  export default defineComponent({
    name: 'Audio',
    components: {
      Tooltip,
      ToggleWithText,
      Sound,
      Button,
      Select,
    },
    setup() {
      const { t } = useI18n();
      const mirrorStore = useStore($mirrorStore);
      const updateAudioDevice = debounce(async (e: AudioDeviceInfo) => {
        if (mirrorStore.value.videoPreview) {
          await requestMirrorStream({
            selectedAudioDevice: e,
            selectedVideoDevice: $devices.getState().selectedVideoDevice,
          });
        } else {
          setActiveDevices({ selectedAudioDevice: e });
        }
      }, MEDIA_BUTTON_DEBOUNCE_GAP);
      const updateSpeakerDevice = (e: AudioDeviceInfo) => {
        selectSpeakerDevice(e);
      };
      const canUseOutputDevice = () => {
        return userAgent === Vendor.chrome;
      };

      return {
        t,
        canUseOutputDevice,
        updateSpeakerDevice,
        updateAudioDevice,
        devices,
      };
    },
  });
