import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38b9b903"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "layouts-wrap",
  ref: "canvas"
}
const _hoisted_2 = { class: "audio-wrapper" }
const _hoisted_3 = { class: "button-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationBlock = _resolveComponent("NotificationBlock")!
  const _component_JoinNotificationList = _resolveComponent("JoinNotificationList")!
  const _component_LayoutsVideo = _resolveComponent("LayoutsVideo")!
  const _component_CallButtonPanel = _resolveComponent("CallButtonPanel")!
  const _component_AudioSlot = _resolveComponent("AudioSlot")!
  const _component_Typography = _resolveComponent("Typography")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Popup = _resolveComponent("Popup")!
  const _component_InvitePeople = _resolveComponent("InvitePeople")!
  const _component_PopUpNotifyRecording = _resolveComponent("PopUpNotifyRecording")!
  const _component_MobileExpandedSettings = _resolveComponent("MobileExpandedSettings")!
  const _component_ChangeLayout = _resolveComponent("ChangeLayout")!
  const _component_ContactList = _resolveComponent("ContactList")!
  const _component_MenuSettings = _resolveComponent("MenuSettings")!
  const _component_ChatArea = _resolveComponent("ChatArea")!
  const _component_UserSettings = _resolveComponent("UserSettings")!
  const _component_Drawer = _resolveComponent("Drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NotificationBlock),
    _createElementVNode("div", {
      class: _normalizeClass(["layouts-panel", _ctx.layoutPanelClasses])
    }, [
      (!_ctx.isTokenNotValid)
        ? (_openBlock(), _createBlock(_component_JoinNotificationList, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_LayoutsVideo, { ref: _ctx.handleLayoutsRef }, null, 512),
        _createVNode(_component_CallButtonPanel, { mode: "admin" })
      ], 512),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.endpointManager, (endpoint) => {
          return (_openBlock(), _createBlock(_component_AudioSlot, {
            key: endpoint.stream.id,
            endpointId: endpoint.stream.id
          }, null, 8, ["endpointId"]))
        }), 128))
      ]),
      (_ctx.popup.opened)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.popup.section === 'token-not-valid')
              ? (_openBlock(), _createBlock(_component_Popup, {
                  key: 0,
                  onClose: _ctx.closePopup
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Typography, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('tokenNotValid.inMeetingTitle')), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_Button, {
                        mode: "secondary",
                        onClick: _ctx.closePopup
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('tokenNotValid.okButton')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_Button, { onClick: _ctx.routeToAuth }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('tokenNotValid.authButton')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ]),
                  _: 1
                }, 8, ["onClose"]))
              : _createCommentVNode("", true),
            (_ctx.popup.section === 'invite')
              ? (_openBlock(), _createBlock(_component_Popup, {
                  key: 1,
                  title: _ctx.t('invitePeople.title'),
                  onClose: _ctx.closePopup
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_InvitePeople)
                  ]),
                  _: 1
                }, 8, ["title", "onClose"]))
              : _createCommentVNode("", true),
            (_ctx.popup.section === 'connection-was-lost')
              ? (_openBlock(), _createBlock(_component_Popup, {
                  key: 2,
                  title: _ctx.t('connectionWasLost.title'),
                  onClose: _ctx.closePopup
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Button, {
                      size: "m",
                      mode: "primary",
                      onClick: _ctx.reloadPage
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('connectionWasLost.button')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }, 8, ["title", "onClose"]))
              : _createCommentVNode("", true),
            (_ctx.popup.section === 'recording')
              ? (_openBlock(), _createBlock(_component_Popup, {
                  key: 3,
                  class: "recording",
                  title: _ctx.t('recording-popup.title'),
                  onClose: _ctx.closePopup
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PopUpNotifyRecording)
                  ]),
                  _: 1
                }, 8, ["title", "onClose"]))
              : _createCommentVNode("", true),
            (_ctx.popup.section === 'sharing-permission')
              ? (_openBlock(), _createBlock(_component_Popup, {
                  key: 4,
                  title: _ctx.t('sharingPermission.title'),
                  onClose: _ctx.closePopup
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Typography, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('sharingPermission.description')), 1)
                      ]),
                      _: 1
                    }),
                    (_ctx.shouldShowAppleLink)
                      ? (_openBlock(), _createBlock(_component_Typography, { key: 0 }, {
                          default: _withCtx(() => [
                            _createTextVNode(" " + _toDisplayString(_ctx.t('sharingPermission.beforeLinkText')) + " ", 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.shouldShowAppleLink)
                      ? (_openBlock(), _createBlock(_component_Typography, {
                          key: 1,
                          fontType: "link",
                          href: "x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('sharingPermission.preferencesLink')) + ".", 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["title", "onClose"]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_Drawer, null, {
        default: _withCtx(() => [
          (_ctx.drawerStore.section === 'generalSettings')
            ? (_openBlock(), _createBlock(_component_MobileExpandedSettings, { key: 0 }))
            : (_ctx.drawerStore.section === 'changeLayout')
              ? (_openBlock(), _createBlock(_component_ChangeLayout, { key: 1 }))
              : (_ctx.drawerStore.section === 'contactList')
                ? (_openBlock(), _createBlock(_component_ContactList, { key: 2 }))
                : (_ctx.drawerStore.section === 'settings')
                  ? (_openBlock(), _createBlock(_component_MenuSettings, {
                      key: 3,
                      class: "menu-drawer",
                      isMenu: true,
                      mode: "admin"
                    }))
                  : (_ctx.drawerStore.section === 'chat')
                    ? (_openBlock(), _createBlock(_component_ChatArea, {
                        key: 4,
                        chatList: _ctx.chatContent.messages
                      }, null, 8, ["chatList"]))
                    : (_ctx.drawerStore.section === 'userSettings')
                      ? (_openBlock(), _createBlock(_component_UserSettings, { key: 5 }))
                      : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ], 2)
  ], 64))
}